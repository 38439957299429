import {AnyScorer} from "../ScorerBase";

type StrCount = Record<string, number>

function splitToPairs(a: string): StrCount {
  if(a.length < 2) return {a: 1}
  let result: StrCount = {}
  for(let i = 0; i < a.length - 1; i++) {
    let x = a.substring(i, i+2)
    result[x] = (result[x] || 0) + 1
  }
  return result
}

/**
 * Counts how many pairs in a have a corresponding pair in b and scales it to 0-1
 * @param aPairs
 * @param bPairs
 */
function scorePairs(aPairs: StrCount, bPairs: StrCount): number {
  return Object.keys(aPairs).map(aPair=>{
    let aAmount = aPairs[aPair]
    let bAmount = bPairs[aPair] || 0
    return Math.min(aAmount, bAmount)
  }).reduce((p,c)=>p+c, 0) / Object.keys(aPairs).length
}

function compare(aString: string, bString: string, aSplits?: StrCount, bSplits?: StrCount) {
  if(aString === bString) return 1
  aSplits = aSplits ?? splitToPairs(aString)
  bSplits = bSplits ?? splitToPairs(bString)
  return Math.min(scorePairs(aSplits, bSplits), scorePairs(bSplits, aSplits))
}


interface PairedCount {
  str: string,
  splits: StrCount
}
function compareByPartsOneWay(a: PairedCount[], b: PairedCount[]) {
  return a
    .map(aP=>Math.max(...b.map(bP=>compare(aP.str, bP.str, aP.splits, bP.splits))))
    .reduce((p,c)=>p+c, 0) / a.length
}

function compareByParts(a: PairedCount[], b: PairedCount[]) {
  let aScore = compareByPartsOneWay(a, b)
  let bScore = compareByPartsOneWay(b, a)
  return Math.min(aScore, bScore)
}

export const textScorer: AnyScorer<string> = {
  scorer: (a,b)=> {
    if (a === b) return 1
    let aParts = a.trim().split(" ").map(aS => ({str: aS, splits: splitToPairs(aS)}))
    let bParts = b.trim().split(" ").map(bS => ({str: bS, splits: splitToPairs(bS)}))
    return compareByParts(aParts, bParts)
  },
  breaker: (a,b) => a === b
}