import {PlatformRepresentation} from "../../model/internal/PlatformRepresentation";
import {Mapping} from "../../model/mapping/Mapping";
import {generateMappingWarehouse} from "./MappingWarehouseGenerator";
import {generateMappingCloud} from "./MappingCloudGenerator";
import {generateMappingComponents} from "./MappingComponentGenerator";

export function generateMapping(source: PlatformRepresentation, target: PlatformRepresentation): Mapping {
  return {
    representation: source,
    platform: {
      warehouse: generateMappingWarehouse(source),
      cloud: generateMappingCloud(source, target)
    },
    orchestrationComponents: generateMappingComponents(source.orchestrationComponents, target.orchestrationComponents),
    transformationComponents: generateMappingComponents(source.transformationComponents, target.transformationComponents)
  }
}