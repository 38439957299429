import {ConverterValues} from "./Arguments";
import {StepInfo} from "../../generic/stepper/Step";
import {ConvertView} from "./ConvertView";

export const ConverterConvertStep: StepInfo<ConverterValues> = {
  component: ConverterRepresentationStepComponent,
  title: "Convert Jobs"
}

export function ConverterRepresentationStepComponent({data}: ConverterValues) {
  if(data) {
    return <ConvertView data={data} />
  } else {
    return <>Invalid</>
  }
}