import {LoadedComp, Param} from "./ApiRequestTypes";
import {ApiRequest} from "./ApiRequest";

export interface ApiRequestArgsArgs<A extends any[], T> {
  args: A,
  functionName: Param<A, T>
  onSuccess: LoadedComp<T, undefined>
}
export function ApiRequestArgs<A extends any[], T extends unknown>({args, functionName, onSuccess}: ApiRequestArgsArgs<A, T>) {
  return <ApiRequest args={args} functionName={functionName} onSuccess={onSuccess} extraProps={undefined} />
}