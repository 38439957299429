import ApiKey from "../../model/api/ApiKey";
import {useCallback, useEffect} from "react";
import {Link} from "react-router-dom";
import {ApiRequestExtraProps} from "../../api/util/ApiRequestExtraProps";

function ListedApiKeySelectorView({data: keys, extra: {apiKeyId, setApiKeyId}}: ApiKeySelectorViewArgs) {
  let onChange = useCallback((e)=>setApiKeyId(e.currentTarget.value), [setApiKeyId])
  useEffect(()=>{
    setApiKeyId(keys[0].id.uniqueId)
  }, [keys, setApiKeyId])
  return <select value={apiKeyId} onChange={onChange} >
        {keys.map(key=><option key={key.id.uniqueId} value={key.id.uniqueId}>{key.label}</option>)}
      </select>
}

interface ApiKeySelectorViewArgs {
  data: ApiKey[],
  extra: ApiKeySelectorArgs
}
function ApiKeySelectorView(props: ApiKeySelectorViewArgs) {
  let {data} = props
  if(data.length === 0) {
    return <div>
      <Link to="/apikey">Warning! You have no API Keys, please create one.</Link>
    </div>
  } else {
    return <ListedApiKeySelectorView {...props} />
  }
}

interface ApiKeySelectorArgs {
  apiKeyId: string
  setApiKeyId: (apiKeyId: string) => void
}
export default function ApiKeySelector(props: ApiKeySelectorArgs) {
  return <div>
    <h3>
      API Key:
    </h3>
    <div style={{paddingLeft: "20px"}}>
      <ApiRequestExtraProps functionName="listKeys" onSuccess={ApiKeySelectorView} extraProps={props} />
    </div>
  </div>
}