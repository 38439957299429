import {useApi} from "@matillion/octo-react-util";
import {useCallback} from "react";
import {ApiCall, LoadedComp, Param} from "./ApiRequestTypes";
import {WithPromise} from "../../util/Loader";
import {Api} from "../Api";

export interface ApiRequestArgs<A extends any[], T, Y> {
  args: A,
  functionName: Param<A, T>
  onSuccess: LoadedComp<T, Y>,
  extraProps: Y
}
export function ApiRequest<A extends any[], T extends unknown, Y>({args, functionName, onSuccess, extraProps}: ApiRequestArgs<A, T, Y>) {
  let api = useApi<Api>()
  let callback = useCallback<()=>Promise<T>>(()=>{
    let call = api[functionName] as unknown as ApiCall<A, T>
    return call.bind(api)(...args)
    /* eslint-disable-next-line */
  }, [api, functionName, ...args])
  let Component = onSuccess
  return <WithPromise
    promise={callback}
    onSuccess={(data, extra)=>{
      let args = {
        data,
        promise: extra,
        extra: extraProps
      }
      return <Component {...args} />
    }}
  />
}
