import {generateElement, MarriageElement, toHolders} from "./MarriageElement";
import {produceScoreGrid, ScoreGrid} from "./ScoreGrid";
import {MarriageQueue} from "./MarriageQueue";
import {MarriageMatches} from "./MarriageMatches";
import {shortCircuit} from "./ShortCircuiter";
import {Id, MarriageResults, Match} from "./Types";
import {AnyScorer, extractScorer} from "./ScorerBase";

export interface MarriageArguments<K extends Id, T> {
  a: Record<K, T>,
  b: Record<K, T>,
  scorer: AnyScorer<T>,
  minScore: number
}

function runQueue<K extends Id, T>(aElements: MarriageElement<T>[], scoreGrid: ScoreGrid, minScore: number): MarriageMatches<K> {
  let matches = new MarriageMatches<K>(scoreGrid)
  let queue = new MarriageQueue(aElements)
  while(!queue.empty) {
    let nextA = queue.pop()
    if(!nextA) return matches
    if(nextA.isDone) continue
    let nextB = nextA.nextOne
    if(nextB.score < minScore) continue
    let canMatch = matches.record(nextA.element.element.id as K, nextB.element.id as K)
    if(!canMatch) {
      queue.push(nextA)
    }
  }
  return matches
}

function toSet<K extends Id>(x: K[]): {[id: string]: boolean} {
  let r: {[id: string]: boolean} = {}
  x.forEach(a=>r[a.toString()] = true)
  return r as Record<K, true>
}

function prepareUnmatched<K extends Id>(aList: K[], bList: K[], matches: Match<K>[]): {a: K[], b: K[]} {
  let aSet = toSet(matches.map(m=>m.a))
  let bSet = toSet(matches.map(m=>m.b))
  let a = aList.filter(x=>!aSet[x.toString()])
  let b = bList.filter(x=>!bSet[x.toString()])
  return {a,b}
}

export function generateMarriage<K extends Id, T>(args: MarriageArguments<K, T>): MarriageResults<K> {
  let aList = toHolders(args.a)
  let bList = toHolders(args.b)
  let matched: Match<K>[] = []
  let {scorer, breaker} = extractScorer(args.scorer)
  if(breaker) {
    let result = shortCircuit<T, K>(aList, bList, breaker)
    aList = result.newAList
    bList = result.newBList
    matched = result.matches
  }
  let scoreGrid = produceScoreGrid(aList, bList, scorer)
  let aElements = aList.map(a=>generateElement(a, bList, scoreGrid.aToB))
  let matches = runQueue<K, T>(aElements, scoreGrid, args.minScore)
  matched.push(...matches.getMatches())
  let unmatched = prepareUnmatched(aList.map(a=>a.id), bList.map(b=>b.id), matched)
  return {matched, unmatchedA: unmatched.a as K[], unmatchedB: unmatched.b as K[]}
}