import {useSelectorArguments} from "../../converter/1select/Arguments";
import {useEditState} from "./edit/EditState";
import {useStep} from "../../generic/stepper/UseStep";
import {ConverterRepresentationStep} from "../../converter/1select/Step";
import {CreateMappingEditStep} from "./edit/CreateEditView";
import Stepper from "../../generic/stepper/Stepper";

interface CreateMappingViewArgs {
  close: ()=>void
}
export default function CreateMappingView({close}: CreateMappingViewArgs) {
  let stepOneArgs = useSelectorArguments()
  let stepTwoArgs = useEditState(stepOneArgs, close)
  let steps = [
    useStep(ConverterRepresentationStep, stepOneArgs),
    useStep(CreateMappingEditStep, stepTwoArgs)
  ]
  return <div>
    <Stepper steps={steps} />
  </div>
}