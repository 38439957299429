import ScoreBlocks from "./ScoreBlocks";
import {ScoreBlock} from "./BlockType";

interface ScoreBarArgs {
  blocks: ScoreBlock[]
}
export default function ScoreBar({blocks}: ScoreBarArgs) {
  if(blocks.length === 0) return null
  let size = blocks.reduce((p, c)=>p+c.size, 0)
  if(size === 0) return null
  let x = 0
  let blockElems = blocks.map((block, index)=>{
    let blockElem = <ScoreBlocks key={index} indexSoFar={x} max={size} {...block}/>
    x += block.size
    return blockElem
  })
  return <div style={{display: "flex"}}>
    {blockElems}
  </div>
}