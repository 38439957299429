import {ApiJobExport} from "./ApiExport";
import {OrchestrationJob} from "../Orchestration";
import {TransformationJob} from "../Transformation";
import {ExportType, isUiExport} from "./JobExport";
import {convertToApiExport} from "./Util";

export interface IntermediateExport {
  orchestration: ApiJobExport<OrchestrationJob>[],
  transformation: ApiJobExport<TransformationJob>[],
  warehouse: string,
  version: string
}

export function toIntermediateExport(exported: ExportType): IntermediateExport {
  let apiExport = convertToApiExport(exported)
  let orchestration = apiExport.objects.filter(x=>x.info.type === "ORCHESTRATION").map(x=>x as ApiJobExport<OrchestrationJob>)
  let transformation = apiExport.objects.filter(x=>x.info.type === "TRANSFORMATION").map(x=>x as ApiJobExport<TransformationJob>)
  return {
    orchestration,
    transformation,
    warehouse: apiExport.environment,
    version: apiExport.version
  }
}

export function fromIntermediateExport(original: ExportType, newIntermediate: IntermediateExport): ExportType {
  if(isUiExport(original)) {
    return {
      jobsTree: original.jobsTree,
      orchestrationJobs: newIntermediate.orchestration.map(x=>x.jobObject),
      transformationJobs: newIntermediate.transformation.map(x=>x.jobObject),
      dbEnvironment: newIntermediate.warehouse,
      version: newIntermediate.version,
      environments: [],
      variables: []
    }
  } else {
    return {
      environment: newIntermediate.warehouse,
      version: newIntermediate.version,
      objects: [...newIntermediate.orchestration, ...newIntermediate.transformation]
    }
  }
}