import {ArgKey} from "../../util/state/SubState";

export interface MapData<A> {
  source: A,
  target: A
}

export function mapMapData<A, B>(a: MapData<A>, map: (a: A)=>B): MapData<B> {
  return {
    source: map(a.source),
    target: map(a.target)
  }
}

export function subMapData<A, B>(a: MapData<A>, key: ArgKey<A, B>): MapData<B> {
  return mapMapData(a, (a)=>a[key] as any as B)
}