import {HashRouter, Routes, Route} from "react-router-dom";
import {SidebarPage} from "./Sidebar";
import InitialPage from "./InitialPage";
import {BasePages} from "./Pages";

export default function AppRouter() {
  let routes = BasePages.map((page)=>{
    let Component = page.component
    let path = page.matchUrl ?? page.url
    return<Route path={path} element={<Component />} />
  })
  return <HashRouter>
    <Routes>
      <Route path="/" element={<SidebarPage />}>
        {routes}
        <Route path="" element={<InitialPage />} />
      </Route>
    </Routes>
  </HashRouter>
}