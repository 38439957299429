import {useSelectorArguments} from "./1select/Arguments";
import Stepper from "../generic/stepper/Stepper";
import {ConverterRepresentationStep} from "./1select/Step";
import {Step} from "../generic/stepper/Step";
import {useReviewArguments} from "./2review/Arguments";
import {ReviewStep} from "./2review/Step";
import {useStep} from "../generic/stepper/UseStep";
import {useConverterArguments} from "./3convert/Arguments";
import {ConverterConvertStep} from "./3convert/Step";

export default function ConverterView() {
  let stepOneArgs = useSelectorArguments()
  let stepTwoArgs = useReviewArguments(stepOneArgs)
  let stepThreeArgs = useConverterArguments(stepTwoArgs)
  let steps: Step<any>[] = [
    useStep(ConverterRepresentationStep, stepOneArgs),
    useStep(ReviewStep, stepTwoArgs),
    useStep(ConverterConvertStep, stepThreeArgs)
  ]
  return <div>
    <Stepper steps={steps} />
  </div>
}