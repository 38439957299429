import {RepresentationId} from "../../../model/internal/RepresentationId";
import {Opt} from "./Types";
import {SingleOpt} from "./SingleOpt";

interface SingleSelectorArgs {
  data: RepresentationId[],
  choice: Opt
}

export function SingleSelector({data, choice}: SingleSelectorArgs) {
  return <div style={{display: "flex", flexDirection: "column"}}>
    {data.map((id) => <SingleOpt key={`${id.warehouseName}-${id.platformName}`} me={id} choice={choice}/>)}
  </div>
}