import {StepState} from "../../generic/stepper/Step";
import {PlatformRepresentation} from "../../../model/internal/PlatformRepresentation";
import {Mapping} from "../../../model/mapping/Mapping";
import {ReviewState} from "../2review/Arguments";

export type OConverterData = ConverterData | undefined
export interface ConverterData {
  source: PlatformRepresentation,
  target: PlatformRepresentation,
  mapping: Mapping
}
export interface ConverterValues extends StepState {
  data: OConverterData,
}
export function useConverterArguments(previous: ReviewState): ConverterValues {
  return {
    data: previous.result.value,
    label: "Convert Jobs",
    valid: previous.result.value !== undefined
  }
}