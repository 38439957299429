import {Opt, ORepresentationId} from "./Types";
import {useState} from "react";
import {StepState} from "../../generic/stepper/Step";

function getName(rep: ORepresentationId): string {
  if(rep === undefined) {
    return "???"
  } else {
    return `${rep.warehouseName} - ${rep.platformName}`
  }
}

export interface SelectorValues extends StepState {
  firstChoice: Opt
  secondChoice: Opt,
}
export function useSelectorArguments(): SelectorValues {
  let [first, setFirst] = useState<ORepresentationId>(undefined)
  let [second, setSecond] = useState<ORepresentationId>(undefined)
  let valid = (first !== undefined && second !== undefined) && first !== second
  let label = undefined
  if(first !== undefined || second !== undefined) {
    label = getName(first) + " -> " + getName(second)
  }
  return {
    firstChoice: {value: first, set: setFirst},
    secondChoice: {value: second, set: setSecond},
    valid,
    label
  }
}