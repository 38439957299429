import {MarriageElement, Score} from "./MarriageElement";

class MarriageQueueElement<T> {
  next: number = 0
  constructor(
    public readonly element: MarriageElement<T>,
  ) {
  }

  get isDone(): boolean {
    return this.next >= this.element.scores.length
  }

  get nextOne(): Score<T> {
    return this.element.scores[this.next++]
  }
}

export class MarriageQueue<T> {
  private list: MarriageQueueElement<T>[]

  constructor(elements: MarriageElement<T>[]) {
    this.list = elements.map(x=>new MarriageQueueElement(x))
  }

  get empty(): boolean {
    return this.list.length === 0
  }

  pop(): MarriageQueueElement<T> | undefined {
    return this.list.pop()
  }

  push(element: MarriageQueueElement<T>) {
    this.list.push(element)
  }
}