import {ConverterData} from "./Arguments";
import {useMemo, useState} from "react";
import {ExportType, isUiExport} from "../../../model/emerald/exports/JobExport";
import {FileLoader} from "./Loader";
import {applyMapping, MapResult} from "../../../engine/mapping/MappingEngine";
import ConvertDownloadButton from "./ConvertDownloadButton";
import {MessageView} from "./MessageView";

function ConvView({converted}: {converted: MapResult | string}) {
  if(typeof converted === "string") {
    return <div>
      Error during conversion:
      <pre>
        {converted}
      </pre>
    </div>
  } else {
    return <>
      <ConvertDownloadButton data={converted[0]} />
      <MessageView messages={converted[1]} />
    </>
  }
}

function ConvertFileView({data, file}: {data: ConverterData, file: ExportType}) {
  let sourceWarehouse = data.source.warehouse.toLowerCase()
  let fileWarehouse = (isUiExport(file) ? file.dbEnvironment : file.environment)?.toLowerCase()
  let isValid = fileWarehouse === sourceWarehouse
  let converted = useMemo<MapResult | string | undefined>(()=>{
    if(!isValid) return undefined
    try {
      return applyMapping(file, data)
    } catch(e) {
      console.error(e)
      if(e instanceof Error) {
        return e.message
      } else if(typeof e === "string") {
        return e
      } else {
        return "Error"
      }
    }
  }, [isValid, data, file])
  return <div>
    {isValid ? <div>
      Completely valid source file
      {converted ? <ConvView converted={converted} /> : null}
    </div> : <div>
      File seems to be from a different source warehouse [{fileWarehouse}] than the desired source [{sourceWarehouse}]
    </div>}
  </div>
}

export function ConvertView({data}: {data: ConverterData}) {
  let [loaded, setLoaded] = useState<ExportType>()
  return <div>
    <FileLoader setFileData={setLoaded} />
    {loaded ? <ConvertFileView data={data} file={loaded} /> : null}
  </div>
}