import {LoadedCompExtraArgs} from "../../../api/util/ApiRequestTypes";
import {RepresentationId} from "../../../model/internal/RepresentationId";
import {Opt} from "./Types";
import {SingleSelector} from "./SingleSelector";
import Header from "../../generic/Header";
import {useHelp} from "@matillion/component-library";


interface ConverterRepresentationSelectorArgs extends LoadedCompExtraArgs<RepresentationId[], {
  firstChoice: Opt,
  secondChoice: Opt
}> {}
export default function ConverterRepresentationSelector({data, extra: {firstChoice, secondChoice}}: ConverterRepresentationSelectorArgs) {
  useHelp(data.length === 0 ? "converter.md#no-platforms" : "converter.md")
  return <div style={{display: "flex", justifyContent: "space-evenly"}}>
    <div style={{maxWidth: "50%"}}>
      <Header text="Source Platform" size="h2" />
      <SingleSelector data={data} choice={firstChoice} />
    </div>
    <div style={{maxWidth: "50%"}}>
      <Header text="Target Platform" size="h2" />
      <SingleSelector data={data} choice={secondChoice} />
    </div>
  </div>
}