import {ScoreMap} from "./ScoreGrid";
import {Id} from "./Types";

export interface Score<T> {
  element: Holder<T>,
  score: number
}

export interface Holder<T> {
  id: Id,
  value: T
}

export function toHolders<T>(map: Record<Id, T>): Holder<T>[] {
  return Object.keys(map)
    .map(id=>({id, value: map[id]}))
}

export interface MarriageElement<T> {
  element: Holder<T>,
  scores: Score<T>[]
}

export function generateElement<T>(a: Holder<T>, bs: Holder<T>[], scoreGrid: ScoreMap) {
  let scoreMap = scoreGrid[a.id]
  let scores = bs.map(b=>{
    return {
      element: b,
      score: scoreMap[b.id]
    }
  }).sort((a,b)=>b.score - a.score)
  return {
    element: a,
    scores,
    scoreMap
  }
}