import {LoadedComp, Param} from "./ApiRequestTypes";
import {ApiRequest} from "./ApiRequest";

let emptyArgs: [] = []
export interface ApiRequestBasicArgs<T> {
  functionName: Param<[], T>
  onSuccess: LoadedComp<T, undefined>
}
export function ApiRequestBasic<T extends unknown>({functionName, onSuccess}: ApiRequestBasicArgs<T>) {
  return <ApiRequest args={emptyArgs} functionName={functionName} onSuccess={onSuccess} extraProps={undefined} />
}