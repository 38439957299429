import ApiKeyView from "./apikey/ApiKeyView";
import PopulatorView from "./populator/PopulatorView";
import RepresentationView from "./representation/RepresentationView";
import ConverterView from "./converter/ConverterView";
import CustomMappingView from "./mapping/CustomMappingView";

export interface BasePage {
  url: string,
  matchUrl?: string,
  label: string
  component: any
}
export const BasePages: BasePage[] = [
  {
    url: "/apikey",
    label: "Manage API Keys",
    component: ApiKeyView
  },
  {
    url: "/populator",
    label: "Component Populator",
    component: PopulatorView
  },
  {
    url: "/representations",
    label: "Known Platforms",
    component: RepresentationView
  },
  {
    url: "/mappings",
    label: "Custom Mappings (WIP)",
    component: CustomMappingView
  },
  {
    url: "/converter",
    label: "Converter",
    component: ConverterView
  }
]