import {BaseApi} from "@matillion/octo-react-util";
import ApiKey from "../model/api/ApiKey";
import {RepresentationId} from "../model/internal/RepresentationId";
import {PlatformRepresentation} from "../model/internal/PlatformRepresentation";
import {CustomMapping, MappingBaseSource, MappingId} from "../model/mapping/CustomMapping";
import {CompMappingPart} from "../model/mapping/Mapping";

export class Api extends BaseApi{

  async generateNewKey(label: string): Promise<void> {
    await this.post<void>("/api/apikey/generate", {label})
  }

  async listKeys(): Promise<ApiKey[]> {
    return await this.get("/api/apikey")
  }

  async deleteKey(id: string): Promise<void> {
    await this.delete(`/api/apikey/${id}`)
  }

  async generateScript(apiKeyId: string, urls: string[]): Promise<string> {
    let response = await this.fetch("/api/generateScript", {body: JSON.stringify({apiKeyId, urls}), headers: {"Content-Type": "application/json"},  method: "POST"})
    let text = await response.text()
    return text
  }

  async listRepresentations(): Promise<RepresentationId[]> {
    return await this.get("/api/representation")
  }

  async getRepresentationFromId(id: RepresentationId): Promise<PlatformRepresentation> {
    return this.getRepresentation(id.warehouseName, id.platformName)
  }

  async getRepresentation(warehouse: string, cloud: string): Promise<PlatformRepresentation> {
    return await this.get("/api/representation/components?" + new URLSearchParams({
      warehouse,
      platform: cloud
    }))
  }

  async listMappings(): Promise<MappingId[]> {
    return this.get("/api/mapping/list")
  }

  async getMapping(id: string): Promise<CustomMapping | null> {
    return this.get("/api/mapping/get/" + id)
  }

  async updateMapping(mapping: CustomMapping): Promise<void> {
    await this.post<void>("/api/mapping/update", mapping)
  }

  async createMapping(label: string, baseSource: MappingBaseSource, mapping: CompMappingPart): Promise<void> {
    await this.post<void>("/api/mapping/new", {label, baseSource, mapping})
  }

  override async handleBadResponse(response: Response): Promise<never> {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      let json = await response.text()
      throw Error("Error with request ["+response.status+"]: " + json)
    } else
      throw Error("Error with request ["+response.status+"]: " + await response.text())
  }
}