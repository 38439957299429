import {useCallback, useState} from "react";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../api/Api";
import ApiKey from "../../model/api/ApiKey";
import {Button, Input, useHelp} from "@matillion/component-library";
import ApiKeyGrid from "./ApiKeyGrid";
import {LoadedCompArgs} from "../../api/util/ApiRequestTypes";
import {ApiRequestBasic} from "../../api/util/ApiRequestBasic";

function ApiKeyCreator({refresh}: {refresh: ()=>void}) {
  let api = useApi<Api>()
  let [text, setText] = useState("")
  let onChange = useCallback((e)=>setText(e.target.value), [setText])
  let onClick = useCallback(async ()=>{
    await api.generateNewKey(text)
    refresh()
  }, [refresh, text, api])
  return <div>
    <Input value={text} onChange={onChange} placeholder="Api Key Label"/>
    <Button onClick={onClick} >Create</Button>
  </div>
}

function ApiKeyListView({data, promise: {refresh}}: LoadedCompArgs<ApiKey[]>) {
  let api = useApi<Api>()
  let deleteKey = useCallback(async (id: string)=>{
    await api.deleteKey(id)
    refresh()
  }, [api, refresh])
  return <div>
    <div>
      <ApiKeyGrid keys={data} deleteKey={deleteKey} />
    </div>
    <ApiKeyCreator refresh={refresh} />
  </div>
}

export default function ApiKeyView() {
  useHelp("api-keys.md")
  return <ApiRequestBasic functionName={"listKeys"} onSuccess={ApiKeyListView} />
}