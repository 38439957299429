import {CustomMapping, MappingId} from "../../../model/mapping/CustomMapping";
import {ApiRequestExtraProps} from "../../../api/util/ApiRequestExtraProps";
import {Button} from "@matillion/component-library";

interface ListMappingsArgs {
  data: MappingId[],
  extra: ListMappingsViewArgs
}
function ListMappings({data, extra: {setEditing, setCreating}}: ListMappingsArgs) {
  return <div>
    {data.map(x=>x.label).join(", ")}
    <Button onClick={setCreating}>New</Button>
  </div>
}

interface ListMappingsViewArgs {
  setEditing: (mapping: CustomMapping)=>void,
  setCreating: ()=>void
}
export default function ListMappingsView(props: ListMappingsViewArgs) {
  return <div>
    <ApiRequestExtraProps functionName="listMappings" onSuccess={ListMappings} extraProps={props} />
  </div>
}