import { AppBase } from '@matillion/octo-react-util';
import React from 'react';
import './App.css';
import {Auth0ContextInterface} from "@auth0/auth0-react";
import {Api} from "./api/Api";
import AppRouter from "./view/AppRouter";

function App() {
  return (
    <AppBase
      applicationName={'Bodger 3'}
      apiProducer={(auth0: Auth0ContextInterface, orgId: string) => new Api(auth0, orgId) }
    >
      <AppRouter />
    </AppBase>
  );
}

export default App;