export type MessageClass = "WARN" | "INFO" | "PARENT"
export interface Message {
  value: string,
  class: MessageClass,
  children: Message[]
}

export class Logger {
  constructor(private readonly data: Message[]) {
  }

  private push(message: string, messageClass: MessageClass): Message {
    let messageObj = {
      value: message,
      class: messageClass,
      children: []
    }
    this.data.push(messageObj)
    return messageObj
  }

  branch(name: string): Logger {
    let child = this.push(name, "PARENT")
    return new Logger(child.children)
  }

  warn(message: string) {
    this.push(message, "WARN")
  }

  info(message: string) {
    this.push(message, "INFO")
  }
}