import {ExportType, isUiExport} from "./JobExport";
import {UiExport} from "./UiExport";
import {ApiExport} from "./ApiExport";
import {OrchestrationJob} from "../Orchestration";
import {TransformationJob} from "../Transformation";
import {buildTree, flattenTree} from "./TreeUtil";

export function convertToUiExport(exported: ExportType): UiExport {
  if(isUiExport(exported)) {
    return exported
  } else {
    let oJobs = exported.objects.filter(x=>x.info.type === "ORCHESTRATION").map(x=>x.jobObject as OrchestrationJob)
    let tJobs = exported.objects.filter(x=>x.info.type === "TRANSFORMATION").map(x=>x.jobObject as TransformationJob)
    return {
      dbEnvironment: exported.environment,
      version: exported.version,
      jobsTree: buildTree(exported.objects),
      orchestrationJobs: oJobs,
      transformationJobs: tJobs,
      environments: [],
      variables: []
    }
  }
}

export function convertToApiExport(exported: ExportType): ApiExport {
  if(isUiExport(exported)) {
    let jobs = flattenTree(exported.jobsTree, exported.orchestrationJobs, exported.transformationJobs)
    return {
      environment: exported.dbEnvironment,
      version: exported.version,
      objects: jobs
    }
  } else {
    return exported
  }
}