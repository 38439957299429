import {Parameter} from "../../../model/emerald/Component";
import {SlotMap} from "../../../model/emerald/HasId";
import {Logger} from "./Logger";
import {isUpdateParameterMapping, ParameterMapping} from "../../../model/mapping/ParameterMapping";
import {ParamMappingMap} from "../../../model/mapping/ComponentMapping";
import {ParamRepresentationMap} from "../../../model/internal/ComponentRepresentation";
import {ParameterRepresentation} from "../../../model/internal/ParameterRepresentation";

export interface ParamData {
  source: ParameterRepresentation,
  targets: ParamRepresentationMap,
  mapping: ParameterMapping
}
export interface ParamMapData {
  sources: ParamRepresentationMap,
  targets: ParamRepresentationMap,
  mappings: ParamMappingMap
}

function mapParameter(parameter: Parameter, data: ParamData | undefined, logger: Logger): Parameter | undefined {
  if(data) {
    let {mapping, targets} = data
    if (mapping && isUpdateParameterMapping(mapping)) {
      let target = targets[mapping.newSlot ?? parameter.slot]
      let newP = {...parameter}
      if (mapping.newSlot && mapping.newSlot.toString() !== parameter.slot.toString()) {
        logger.info(`Moving parameter to new slot ${mapping.newSlot}`)
        newP.slot = mapping.newSlot
      }
      if(target && (target.name !== parameter.name)) {
        logger.info(`Renaming parameter from "${parameter.name}" to "${target.name}"`)
        newP.name = target.name
      }
      return newP
    }
  }
  logger.warn("Parameter has no associated target and is getting deleted.")
  return undefined
}

function getMapData(data: ParamMapData, slot: number): ParamData | undefined {
  let mapping = data.mappings[slot]
  if(mapping) {
    return {
      mapping,
      source: data.sources[slot],
      targets: data.targets
    }
  }
}
export function mapParameters(parameters: SlotMap<Parameter>, data: ParamMapData, logger: Logger): SlotMap<Parameter> {
  let newMap: SlotMap<Parameter> = {}
  Object.values(parameters).forEach((oldParameter)=>{
    let paramLogger = logger.branch(`Parameter - ${oldParameter.name}`)
    let subData = getMapData(data, oldParameter.slot)
    let newParameter = mapParameter(oldParameter, subData, paramLogger)
    if(newParameter) {
      newMap[newParameter.slot] = newParameter
    }
  })
  return newMap
}