import {ComponentMappingMap} from "../../../../model/mapping/Mapping";
import {CompRepresentationMap} from "../../../../model/internal/PlatformRepresentation";
import {isDeleteComponentMapping, isUpdateComponentMapping} from "../../../../model/mapping/ComponentMapping";
import {cast} from "../../../../util/ArrayCast";
import ScoreBar from "../../../generic/score/ScoreBar";
import {ScoreBlock} from "../../../generic/score/BlockType";

interface ComponentScoreBarArgs {
  source: CompRepresentationMap,
  target: CompRepresentationMap,
  mapping: ComponentMappingMap
}
export function ComponentScoreBar({source, mapping}: ComponentScoreBarArgs) {
  let fullCount = Object.keys(source).length
  let values = Object.values(mapping)
  let updates = cast(values, isUpdateComponentMapping)
  let deletes = cast(values, isDeleteComponentMapping)
  let perfect = updates.filter(x=>x.mappingScore === 1)
  let scores: ScoreBlock[] = [
    {
      display: {
        colour: "green",
        label: `Perfect matches (${perfect.length}/${fullCount})`
      },
      size: perfect.length
    },
    {
      display: {
        colour: "orange"
      },
      size: updates.length - perfect.length
    },
    {
      display: {
        colour: "red",
      },
      size: deletes.length
    }
  ]
  return <div>
    <ScoreBar blocks={scores} />
  </div>
}