import {ScoreGrid} from "./ScoreGrid";
import {Id, Match} from "./Types";

export class MarriageMatches<K extends Id> {
  aToB: Record<Id, K> = {}
  bToA: Record<Id, K> = {}

  constructor(private readonly scoreGrid: ScoreGrid) {
  }

  record(a: K, b: K): boolean {
    let existingA = this.bToA[b]
    let existingB = this.aToB[a]
    if(existingB) {
      let newABScore = this.scoreGrid.aToB[a][b]
      let oldABScore = this.scoreGrid.aToB[a][existingB]
      if(oldABScore > newABScore) {
        return false
      }
    }

    if(existingA) {
      let newBAScore = this.scoreGrid.bToA[b][a]
      let oldBAScore = this.scoreGrid.bToA[b][existingA]
      if(oldBAScore > newBAScore) {
        return false
      }
    }

    if(existingB) {
      delete this.bToA[existingB]
    }
    if(existingA) {
      delete this.aToB[existingA]
    }

    this.aToB[a] = b
    this.bToA[b] = a
    return true
  }

  getMatches(): Match<K>[] {
    return Object.keys(this.aToB).map(a=>{
      let aK = a as K
      let b = this.aToB[a] as K
      let score = this.scoreGrid.aToB[a][b] as number
      return {a: aK, b, score}
    })
  }
}