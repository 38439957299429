import {Setter} from "../../util/Setter";
import {useCallback, useState} from "react";
import {Button, Field, Input} from "@matillion/component-library";
import {distinct} from "../../util/Distinct";

function validateUrl(urls: string[], urlInput: string) {
  let url = urlInput.trim()
  if(urls.includes(url) || !url || url === "") return false
  try {
    let urlObj = new URL(url)
    return urlObj.protocol.startsWith("http");
  } catch(e) {
    return false
  }
}

interface UrlSelectorRowArgs {
  index: number
  url: string,
  deleteUrl: (index: number)=>void
}
function UrlSelectorRow({index, url, deleteUrl}: UrlSelectorRowArgs) {
  let deleteMe=useCallback(()=>deleteUrl(index), [deleteUrl, index])
  return <div style={{display: "flex", justifyContent: "space-between", width: "50%"}}>
    <div>{url}</div>
    <div>
      <Button as="span" alt="red" size="sm" onClick={deleteMe}>X</Button>
    </div>
  </div>
}

const wildcards = ["https://*/*", "http://*/*"]

function UrlAdder({urls, setUrls}: UrlSelectorArgs) {
  let [current, setCurrent] = useState<string>("")
  let addRow = useCallback((x)=>setUrls(urls=>[...urls, x].filter(distinct)), [setUrls])

  let setMatchAll = useCallback(()=>{
    wildcards.forEach(x=>addRow(x))
  }, [addRow])

  let addCurrent = useCallback(()=>addRow(current), [addRow, current])
  let onChange = useCallback(e=>setCurrent(e.currentTarget.value), [setCurrent])
  let isValid = validateUrl(urls, current)
  let hasWildcards = wildcards.every(x=>urls.includes(x))
  return <div style={{display: "flex"}}>
    <div style={{flexGrow: 1}}>
      <Field title="New URL" value={current}>
        <Input value={current} onChange={onChange} placeholder="Enter the URL of any Matillion ETL instances to get component data from" />
      </Field>
    </div>
    <div style={{alignSelf: "flex-end"}}>
      <Button disabled={!isValid} onClick={addCurrent}>Add URL</Button>
    </div>
    <div style={{alignSelf: "flex-end", visibility: hasWildcards ? "hidden" : undefined}}>
      <Button onClick={setMatchAll}>Add Wildcards</Button>
    </div>
  </div>
}

interface UrlSelectorArgs {
  urls: string[],
  setUrls: Setter<string[]>
}
export default function UrlSelector({urls, setUrls}: UrlSelectorArgs) {
  let deleteUrl = useCallback((index: number)=>setUrls(urls=>{
    let newUrls = [...urls]
    newUrls.splice(index, 1)
    return newUrls
  }), [setUrls])

  return <div>
    <h3>URLs:</h3>
    <div style={{paddingLeft: "20px"}}>
      <div>
        <UrlAdder urls={urls} setUrls={setUrls} />
      </div>
      <div style={{paddingLeft: "20px", fontSize: "0.8em"}}>
        {urls.map((url, index)=><UrlSelectorRow {...{index, url, deleteUrl}} />)}
      </div>
    </div>

  </div>
}