import {MapData} from "../../../../model/internal/MapHelper";
import {PlatformRepresentation} from "../../../../model/internal/PlatformRepresentation";
import {CompMappingPart, Mapping} from "../../../../model/mapping/Mapping";
import {State} from "../../../../util/State";
import {useCallback} from "react";
import {Button, Input} from "@matillion/component-library";
import {CompMappingEditor} from "../../editor/CompMappingEditor";
import Overlay from "../../../generic/overlay/Overlay";
import {useCompMapEditingState, useCompMapSub} from "./CompMapStateBreakdown";

interface LoadedMappingEditorArgs {
  representation: MapData<PlatformRepresentation>,
  base: Mapping,
  currentLabel: State<string>,
  current: State<CompMappingPart>,
  close: () => void
}
export function LoadedMappingEditor({representation, base, currentLabel: {value: label, set: setLabel}, current}: LoadedMappingEditorArgs) {
  let setLabelValue = useCallback((e)=>setLabel(e.target.value), [setLabel])
  let {editing, editOrch, editTran, stopEdit} = useCompMapEditingState()
  let {subState, subBase, subRepr} = useCompMapSub(representation, base, current, editing)
  if(editing) {
    return <Overlay close={stopEdit}>
      <Button onClick={stopEdit}>Stop Editing {editing === "orchestrationComponents" ? "Orchestration Components" : "Transformation Components"}</Button>
      <CompMappingEditor representation={subRepr} baseMapping={subBase} current={subState}/>
    </Overlay>
  }
  return <div style={{position: "relative"}}>
    <Input value={label} onChange={setLabelValue} label="Mapping Name"/>
    <Button onClick={editOrch}>Edit Orchestration</Button>
    <Button onClick={editTran}>Edit Transformation</Button>
    {editing ? <Overlay close={stopEdit}>
      <Button onClick={stopEdit}>Stop Editing {editing === "orchestrationComponents" ? "Orchestration Components" : "Transformation Components"}</Button>
      <CompMappingEditor representation={subRepr} baseMapping={subBase} current={subState}/>
    </Overlay> : null}
  </div>
}