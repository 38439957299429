import {ExportType} from "../../model/emerald/exports/JobExport";
import {Mapping} from "../../model/mapping/Mapping";
import {
  fromIntermediateExport,
  IntermediateExport,
  toIntermediateExport
} from "../../model/emerald/exports/IntermediateExport";
import {Logger, Message} from "./engine/Logger";
import {PlatformRepresentation} from "../../model/internal/PlatformRepresentation";
import {JobType} from "../../model/emerald/exports/JobInfo";
import {JobMapData, mapJobs} from "./engine/JobLayer";
import {getOrchestrationJobConnectorHandler, getTransformationJobConnectorHandler} from "./engine/ConnectorHandler";

export interface MappingData {
  source: PlatformRepresentation,
  target: PlatformRepresentation,
  mapping: Mapping
}

type Shorthand = "orchestrationComponents" | "transformationComponents"
export type MapResult = [ExportType, Message[]]
function getData(data: MappingData, type: JobType): JobMapData {
  let x: Shorthand = type === "ORCHESTRATION" ? "orchestrationComponents" : "transformationComponents"
  return {
    source: data.source[x],
    target: data.target[x],
    mappings: data.mapping[x]
  }
}
export function applyMapping(exported: ExportType, data: MappingData): MapResult {
  let messages: Message[] = []
  let logger = new Logger(messages)
  let intermediate = toIntermediateExport(exported)
  let newOrchs = mapJobs(intermediate.orchestration, getOrchestrationJobConnectorHandler, getData(data, "ORCHESTRATION"), logger.branch("Orchestration"))
  let newTrans = mapJobs(intermediate.transformation, getTransformationJobConnectorHandler, getData(data, "TRANSFORMATION"), logger.branch("Transformation"))
  let newInter: IntermediateExport = {
    orchestration: newOrchs,
    transformation: newTrans,
    warehouse: data.target.warehouse,
    version: intermediate.version
  }
  return [fromIntermediateExport(exported, newInter), messages]
}