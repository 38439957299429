import {JobTree} from "./UiExport";
import {ApiJobExport} from "./ApiExport";
import {OrchestrationJob} from "../Orchestration";
import {TransformationJob} from "../Transformation";
import Job from "../Job";

interface ID {
  current: number
}

function getTree(root: JobTree, id: ID, path: string[]): JobTree {
  if(path.length === 0) return root
  let [pathPart, ...remainingPath] = path
  let child: JobTree | undefined = root.children.find(x=>x.name === pathPart)
  if(!child) {
    child = {
      id: id.current++,
      name: pathPart,
      children: [],
      jobs: []
    }
    root.children.push(child)
  }
  return getTree(child, id, remainingPath)
}

export function buildTree(jobs: ApiJobExport<any>[]): JobTree {
  let id: ID = {current: 1}
  let root: JobTree = {
    id: id.current++,
    name: "ROOT",
    children: [],
    jobs: []
  }
  jobs.forEach(j=>{
    let [, ...path] = j.path
    let tree = getTree(root, id, path)
    let newId = id.current++
    j.info.id = newId
    j.jobObject.id = newId
    tree.jobs.push(j.info)
  })
  return root
}

export function buildJobMap<J extends Job<any>>(list: J[]): Record<number, J> {
  let r: Record<number, J> = {}
  list.forEach(x=>r[x.id] = x)
  return r
}

function flattenTreeInt(path: string[], tree: JobTree, oMap: Record<number, OrchestrationJob>, tMap: Record<number,TransformationJob>): ApiJobExport<any>[] {
  let thisLevel: ApiJobExport<any>[] = tree.jobs.map(info=>{
    let j = info.type === "ORCHESTRATION" ? oMap[info.id] : tMap[info.id]
    return {
      path,
      info,
      jobObject: j
    }
  })
  let children = tree.children.flatMap(child=>{
    let newPath = [...path, child.name]
    return flattenTreeInt(newPath, child, oMap, tMap)
  })
  return [...thisLevel, ...children]
}

export function flattenTree(tree: JobTree, oJobs: OrchestrationJob[], tJobs: TransformationJob[]): ApiJobExport<any>[] {
  let oMap = buildJobMap(oJobs)
  let tMap = buildJobMap(tJobs)
  return flattenTreeInt([], tree, oMap, tMap)
}