import {useCallback, useState} from "react";
import ApiKeySelector from "./ApiKeySelector";
import UrlSelector from "./UrlSelector";
import useSavedState from "../../util/SavedState";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../api/Api";
import {Button} from "@matillion/component-library";
import saveFile from "js-file-download"

export default function PopulatorBuilder() {
  let api = useApi<Api>()
  let [apiKeyId, setApiKeyId] = useState<string>("")
  let [urls, setUrls, saveUrls] = useSavedState<string[]>("populatorUrls", ["https://my-matillion-url.com/"])
  let enabled = urls.length > 0 && apiKeyId !== ""
  let generate = useCallback(async ()=>{
    let script = await api.generateScript(apiKeyId, urls)
    saveFile(script, "Bodger3Populator.user.js")
    saveUrls()
  }, [api, apiKeyId, urls, saveUrls])
  return <div>
    <h2>Generate Populator</h2>
    <ApiKeySelector apiKeyId={apiKeyId} setApiKeyId={setApiKeyId} />
    <UrlSelector urls={urls} setUrls={setUrls} />
    <Button disabled={!enabled} onClick={generate}>Generate Script</Button>
  </div>
}