import ApiKey from "../../model/api/ApiKey";
import {Checkbox, DataGrid} from "@matillion/component-library";
import {useMemo} from "react";
import {DataGridColumnProps} from "@matillion/component-library/dist/components/DataGrid/types";
import ApiKeyGridActions, {ApiKeyGridActionArgs} from "./ApiKeyGridActions";

function ApiKeyEnabledColumn({enabled}: ApiKey) {
  return <Checkbox checked={enabled} readOnly />
}

let cols: DataGridColumnProps<ApiKeyRow>[] = [
  {
    key: "label",
    title: "API Key"
  },
  {
    key: "self",
    title: "Enabled",
    as: ApiKeyEnabledColumn
  },
  {
    key: "actions",
    title: "",
    as: ApiKeyGridActions
  }
]

interface ApiKeyRow {
  id: string,
  self: ApiKey,
  label: string,
  actions: ApiKeyGridActionArgs
}

interface ApiKeyArgs {
  keys: ApiKey[],
  deleteKey: (id: string)=>Promise<void>,
}
export default function ApiKeyGrid({keys, deleteKey}: ApiKeyArgs) {
  let mapped = useMemo<ApiKeyRow[]>(()=>keys.map((k)=>({
    ...k,
    id: k.id.uniqueId,
    self: k,
    actions: {
      self: k,
      deleteKey,
      setKeyEnabled: async ()=>{}
    },
  })), [keys, deleteKey])
  return <DataGrid columns={cols} rows={mapped} />
}