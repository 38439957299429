import ApiKey from "../../model/api/ApiKey";
import {Button} from "@matillion/component-library";
import {useCallback, useState} from "react";

interface LoadyButtonArgs {
  children: any,
  onClick: ()=>Promise<void>
}
function LoadyButton({children, onClick}: LoadyButtonArgs) {
  let [loading, setLoading] = useState(false)
  let myClick = useCallback(async ()=>{
    setLoading(true)
    await onClick()
    setLoading(false)
  }, [setLoading, onClick])
  return <Button disabled={loading} onClick={myClick}>{children}</Button>
}

export interface ApiKeyGridActionArgs {
  self: ApiKey,
  deleteKey: (id: string)=>Promise<void>
  setKeyEnabled: (id: string, enabled: boolean)=>Promise<void>
}
export default function ApiKeyGridActions({self, deleteKey, setKeyEnabled}: ApiKeyGridActionArgs) {
  let id = self.id.uniqueId
  let deleteMe = useCallback(()=>deleteKey(id), [id, deleteKey])
  let setEnabled = useCallback(()=>setKeyEnabled(id, !self.enabled), [id, self, setKeyEnabled])
  return <div style={{display: "flex", justifyContent: "space-evenly", width: "100%"}}>
    <LoadyButton onClick={deleteMe}>Delete</LoadyButton>
    <LoadyButton onClick={setEnabled}>{self.enabled ? "Disable" : "Enable"}</LoadyButton>
  </div>
}