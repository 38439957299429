import {Holder} from "./MarriageElement";
import {Id} from "./Types";
import {Scorer} from "./ScorerBase";

export type ScoreMap = Record<Id, Record<Id, number>>
export interface ScoreGrid {
  aToB: ScoreMap,
  bToA: ScoreMap,
}

function addAtoBScores<T>(a: Holder<T>, bList: Holder<T>[], base: ScoreGrid, scorer: Scorer<T>) {
  let aMap: Record<Id, number> = {}
  bList.forEach((b)=>{
    aMap[b.id] = scorer(a.value, b.value)
  })

  base.aToB[a.id] = aMap
}

export function produceScoreGrid<T>(aList: Holder<T>[], bList: Holder<T>[], scorer: Scorer<T>): ScoreGrid {
  let base: ScoreGrid = {
    aToB: {},
    bToA: {}
  }
  aList.forEach((a)=>{
    addAtoBScores(a, bList, base, scorer)
  })
  bList.forEach((b)=>{
    let bMap: Record<Id, number> = base.bToA[b.id] = {}
    aList.forEach((a)=>bMap[a.id]=base.aToB[a.id][b.id])
  })
  return base
}