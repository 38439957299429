import {RepresentationId} from "../../../model/internal/RepresentationId";
import {useCallback} from "react";
import {Field, Radio} from "@matillion/component-library";
import {Opt} from "./Types";

export function SingleOpt({me, choice}: { me: RepresentationId, choice: Opt }) {
  let {value, set} = choice
  let label = `${me.warehouseName} - ${me.platformName}`
  let setMe = useCallback((e) => {
    console.log("HI")
    set(p=>p === me ? undefined : me)
  }, [me, set])
  return <div style={{paddingBottom: "5px"}}>
    <Field
      inputComponent={Radio}
      value={label}
      text={label}
      checked={me === value}
      onChange={setMe}
    />
  </div>
}