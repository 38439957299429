import {StepState} from "../../../generic/stepper/Step";
import {RepresentationId} from "../../../../model/internal/RepresentationId";
import {State, useStateObject} from "../../../../util/State";
import {SelectorValues} from "../../../converter/1select/Arguments";
import {CompMappingPart} from "../../../../model/mapping/Mapping";

export interface EditState extends StepState {
  first?: RepresentationId,
  second?: RepresentationId,
  currentLabel: State<string>,
  current: State<CompMappingPart>,
  close: () => void
}

export function useEditState(input: SelectorValues, finish: ()=>void): EditState {
  let current = useStateObject<CompMappingPart>({orchestrationComponents: {}, transformationComponents: {}})
  let currentLabel = useStateObject("New Mapping")
  let first = input.firstChoice.value
  let second = input.secondChoice.value
  let valid = first !== undefined && second !== undefined
  return {
    first, second, current, currentLabel, close: finish, valid
  }
}