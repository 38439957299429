import {PlatformRepresentation} from "../../model/internal/PlatformRepresentation";
import {MappingWarehouse} from "../../model/mapping/MappingPlatform";

let defaultMapping: MappingWarehouse = {
  name: "",
  caseSensitive: true
}

let types: Record<string, Partial<MappingWarehouse>> = {
  "snowflake": {
    caseSensitive: true
  },
}

export function generateMappingWarehouse(source: PlatformRepresentation): MappingWarehouse {
  let lookup: Partial<MappingWarehouse> = types[source.warehouse] ?? {}
  return {
    ...defaultMapping,
    ...lookup,
    name: source.warehouse
  }
}