import {ComponentType, Mapping} from "../../../model/mapping/Mapping";
import {PlatformRepresentation} from "../../../model/internal/PlatformRepresentation";
import {ComponentScoreBar} from "./base/ComponentScore";
import {ComponentList} from "./base/ComponentList";
import {useCallback, useState} from "react";
import {isUpdateComponentMapping} from "../../../model/mapping/ComponentMapping";
import UpdateComponentInspectionView from "./componentinspect/UpdateComponentInspectionView";
import {useToggleState} from "../../../util/State";
import {Button, useHelp} from "@matillion/component-library";

interface ComponentTypedListArgs {
  type: ComponentType,
  source: PlatformRepresentation
  target: PlatformRepresentation,
  mapping: Mapping,
  openComponent: (inspected: InspectedComp)=>void
}
function ComponentTypedList({source, target, mapping, type, openComponent}: ComponentTypedListArgs) {
  useHelp("converter.md#review-mappings")
  let [isOpen, toggle] = useToggleState(false)
  let myOpenComponent = useCallback((id)=>openComponent({type, id}), [type, openComponent])


  let label = type === "orchestrationComponents" ? "Orchestration Components:" : "Transformation Components:"
  let s = source[type]
  let t = target[type]
  let m = mapping[type]
  return <div style={{width: "50%"}}>
    <div style={{display: "flex", justifyContent: "space-between"}}>
      {label}
      <Button size="sm" onClick={toggle}>{isOpen ? "Close" : "Open"}</Button>
    </div>
    <ComponentScoreBar source={s} target={t} mapping={m} />
    {isOpen ? <ComponentList source={s} target={t} mapping={m} open={myOpenComponent}/> : null}
  </div>
}

export interface InspectedComp {
  id: number,
  type: ComponentType
}

interface ReviewGenerateMappingViewArgs {
  source: PlatformRepresentation,
  target: PlatformRepresentation,
  mapping: Mapping
}
export function ReviewMappingView({source, target, mapping}: ReviewGenerateMappingViewArgs) {
  let [inspected, setInspected] = useState<InspectedComp | undefined>()
  let close = useCallback(()=>setInspected(undefined), [setInspected])
  if(inspected) {
    let sourceComp = source[inspected.type][inspected.id]
    let maps = mapping[inspected.type][inspected.id]
    if(isUpdateComponentMapping(maps)) {
      let targetComp = target[inspected.type][maps.newImplementationId ?? inspected.id]
      return <UpdateComponentInspectionView source={sourceComp} target={targetComp} mapping={maps} close={close} />
    }
  }
  let x = {source, target, mapping, openComponent: setInspected}
  return <div style={{display: "flex"}}>
    <ComponentTypedList type="orchestrationComponents" {...x} />
    <ComponentTypedList type="transformationComponents" {...x} />
  </div>
}