import {StepInfo} from "../../../generic/stepper/Step";
import {EditState} from "./EditState";
import {RepresentationId} from "../../../../model/internal/RepresentationId";
import {useCallback} from "react";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../../../api/Api";
import {generateMapping} from "../../../../engine/analysis/MappingGenerator";
import {State} from "../../../../util/State";
import {CompMappingPart} from "../../../../model/mapping/Mapping";
import {WithPromise} from "../../../../util/Loader";
import {LoadedMappingEditor} from "./LoadedMappingEditor";

export const CreateMappingEditStep: StepInfo<EditState> = {
  title: "Create Mapping",
  component: CreateMappingEditView
}

interface ValidEditState {
  first: RepresentationId,
  second: RepresentationId
  currentLabel: State<string>,
  current: State<CompMappingPart>,
  close: () => void
}
function CreateMappingEditLoader({first, second, ...rest}: ValidEditState) {
  let api = useApi<Api>()
  let promise = useCallback(async ()=>{
    let fRep = await api.getRepresentationFromId(first)
    let sRep = await api.getRepresentationFromId(second)
    let baseMapping = generateMapping(fRep, sRep)
    return {
      representation: {source: fRep, target: sRep},
      base: baseMapping
    }
  }, [first, second, api])
  return <WithPromise
    promise={promise}
    onSuccess={(d,e)=><LoadedMappingEditor {...rest} {...d} />}
  />
}

export function CreateMappingEditView({first, second, ...rest}: EditState) {
  return <div>
    {first && second ? <CreateMappingEditLoader first={first} second={second} {...rest} />: null}
  </div>
}