import {ExportType} from "../../../model/emerald/exports/JobExport";
import {Button} from "@matillion/component-library";
import {useCallback} from "react";
import fileDownload from "js-file-download"
import {convertToApiExport, convertToUiExport} from "../../../model/emerald/exports/Util";

interface ConvertDownloadButtonArgs {
  data: ExportType
}
export default function ConvertDownloadButton({data}: ConvertDownloadButtonArgs) {
  let downloadApi = useCallback(()=>{
    fileDownload(JSON.stringify(convertToApiExport(data)), "BodgerAPIExport.json")
  }, [data])
  let downloadUI = useCallback(()=>{
    fileDownload(JSON.stringify(convertToUiExport(data)), "BodgerUIExport.json")
  }, [data])
  return <div style={{display: "flex"}}>
    Converted jobs:
    <Button onClick={downloadApi}>Download as API Export</Button>
    <Button onClick={downloadUI}>Download as UI Export</Button>
  </div>
}