import {AnyScorer, extractScorer, Scorer} from "../ScorerBase";
import {ShortCircuitBreaker} from "../ShortCircuiter";

export function mapScorer<A,B>(map: (a: A)=>B, anyScorer: AnyScorer<B>): AnyScorer<A> {
  let {scorer, breaker} = extractScorer(anyScorer)
  let newScorer: Scorer<A> = (a, b)=>scorer(map(a), map(b))
  let newBreaker: ShortCircuitBreaker<A> | undefined = undefined
  if(breaker) {
    newBreaker = (a,b)=>breaker!(map(a), map(b))
  }
  return {scorer: newScorer, breaker: newBreaker}
}