import {CustomMapping} from "../../../model/mapping/CustomMapping";

interface EditMappingViewArgs {
  close: ()=>void,
  editing: CustomMapping,
  setEditing: (newMapping: CustomMapping)=>void
}
export default function EditMappingView({close, editing, setEditing}: EditMappingViewArgs) {
  return <div>
    Editing {JSON.stringify(editing)}
  </div>
}