import PopulatorBuilder from "./PopulatorBuilder";
import {useHelp} from "@matillion/component-library";


export default function PopulatorView() {
  useHelp("populator.md")
  return <div style={{display: "flex", minHeight: "100%"}}>
      <div style={{"flexGrow": 1}}>
        <PopulatorBuilder />
      </div>
    </div>
}