import {cast} from "../../../../util/ArrayCast";
import ScoreBar from "../../../generic/score/ScoreBar";
import {ParamRepresentationMap} from "../../../../model/internal/ComponentRepresentation";
import {
  isDeleteParameterMapping,
  isUpdateParameterMapping,
  ParameterMapping
} from "../../../../model/mapping/ParameterMapping";
import {ScoreBlock} from "../../../generic/score/BlockType";

interface ParamScoreBarArgs {
  source: ParamRepresentationMap,
  target: ParamRepresentationMap,
  mapping: Record<number, ParameterMapping>
}
export function ParamScoreBar({source, mapping}: ParamScoreBarArgs) {
  let values = Object.values(mapping)
  let updates = cast(values, isUpdateParameterMapping)
  let deletes = cast(values, isDeleteParameterMapping)
  let perfect = updates.filter(x=>x.mappingScore === 1)
  let scores: ScoreBlock[] = [
    {
      display: {
        colour: "green"
      },
      size: perfect.length
    },
    {
      display: {
        colour: "orange"
      },
      size: updates.length - perfect.length
    },
    {
      display:{
        colour: "red"
      },
      size: deletes.length
    }
  ]
  return <div>
    <ScoreBar blocks={scores} />
  </div>
}