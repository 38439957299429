import {useCallback} from "react";
import {Button} from "@matillion/component-library";

export interface ComponentActionArgs {
  id: number,
  open: (id: number)=> void
}
export function ComponentActions({id, open}: ComponentActionArgs) {
  let openMe = useCallback(()=>open(id), [open, id])
  return <div>
    <Button onClick={openMe}>View</Button>
  </div>
}