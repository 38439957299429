import {RepresentationId} from "../../model/internal/RepresentationId";
import {Button, DataGrid} from "@matillion/component-library";
import {DataGridColumnProps} from "@matillion/component-library/dist/components/DataGrid/types";
import {useCallback} from "react";

function OpenButton({self, open}: RepListExtraData) {
  let openMe = useCallback(()=>open(self), [open, self])
  return <Button onClick={openMe}>View</Button>
}

interface RepListExtraData {
  self: RepresentationId,
  open: (id: RepresentationId)=>void
}
interface RepListThing {
  id: string,
  warehouseName: string,
  platformName: string,
  data: RepListExtraData
}
const cols: DataGridColumnProps<RepListThing>[] = [
  {
    key: "warehouseName",
    title: "Warehouse"
  },
  {
    key: "platformName",
    title: "Cloud Platform"
  },
  {
    key: "data",
    title: "",
    as: OpenButton
  }
]

interface PlatformRepresentationListArgs {
  data: RepresentationId[],
  extra: {setOpen: (id: RepresentationId)=>void}
}
export default function PlatformRepresentationList({data, extra: {setOpen}}: PlatformRepresentationListArgs) {
  let x = data.map(i=>({id: `${i.warehouseName}.${i.platformName}`, data: {self:i, open: setOpen}, ...i}))
  return <div>
    <DataGrid columns={cols} rows={x} />
  </div>
}