import {ShortCircuitBreaker} from "./ShortCircuiter";

export type Scorer<T> = (a: T, b: T) => number

export interface BuiltScorer<T> {
  scorer: Scorer<T>,
  breaker?: ShortCircuitBreaker<T>
}

export type AnyScorer<T> = Scorer<T> | BuiltScorer<T>

export function extractScorer<T>(anyScorer: AnyScorer<T>): BuiltScorer<T> {
  if(typeof(anyScorer) === "function"){
    return {scorer: anyScorer}
  } else {
    return anyScorer
  }
}