import {MapData, subMapData} from "../../../../model/internal/MapHelper";
import {CompRepresentationMap, PlatformRepresentation} from "../../../../model/internal/PlatformRepresentation";
import {CompMappingPart, ComponentMappingMap, Mapping} from "../../../../model/mapping/Mapping";
import {State} from "../../../../util/State";
import {useCallback, useState} from "react";
import {useSubState} from "../../../../util/state/SubState";

export type Editing = undefined | "orchestrationComponents" | "transformationComponents"
export function useCompMapEditingState() {
  let [editing, setEditing] = useState<Editing>()
  let editOrch = useCallback(()=>setEditing("orchestrationComponents"), [])
  let editTran = useCallback(()=>setEditing("transformationComponents"), [])
  let stopEdit = useCallback(()=>setEditing(undefined), [])
  return {editOrch, editTran, stopEdit, editing}
}

export function useCompMapSub(representation: MapData<PlatformRepresentation>, base: Mapping, current: State<CompMappingPart>, editing: Editing) {
  let nEditing = editing ?? "orchestrationComponents"
  let subState = useSubState<CompMappingPart, ComponentMappingMap>(nEditing, current)
  let subBase = base[nEditing]
  let subRepr = subMapData<PlatformRepresentation, CompRepresentationMap>(representation, nEditing)
  return {subState, subBase, subRepr}
}

