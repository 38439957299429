import {Step, StepState} from "./Step";
import {useCallback, useState} from "react";
import {Button, ProgressTracker} from "@matillion/component-library";

function StepFunction<T extends StepState>(x: Step<T>) {
  let Comp = x.component
  return <Comp {...x.state} />
}

interface StepperArgs {
  steps: Step<any>[]
}
export default function Stepper({steps}: StepperArgs) {
  let [step, setStep] = useState(0)
  let stages = steps.map((stepData, index)=>{
    let {state} = stepData
    return {
      active: step === index,
      key: stepData.title,
      stepData: state.label,
      stepName: stepData.title,
      progress: state.progress ?? state.valid ? 1 : 0
    }
  })
  let current = steps[step]
  let stepBack = useCallback(()=>setStep(x=>Math.max(0, x-1)), [setStep])
  let stepForward = useCallback(()=>setStep(x=>Math.min(steps.length -1, x + 1)), [steps, setStep])
  return <div>
    <ProgressTracker stages={stages} />
    <StepFunction {...current} />
    <div>
      {step > 0 ? <Button onClick={stepBack}>Previous</Button> : null}
      {step < steps.length - 1 ? <Button onClick={stepForward} disabled={!current.state.valid}>Next</Button> : null}
    </div>
  </div>
}