import {OPlatformRepresentation, ReviewState} from "./Arguments";
import {useCallback, useEffect} from "react";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../../api/Api";
import {ORepresentationId} from "../1select/Types";
import {State} from "../../../util/State";
import {usePromise} from "../../../util/Loader";
import {LoadingSpinner} from "@matillion/component-library";
import {ReviewMappingView} from "./ReviewMappingView";
import {Mapping} from "../../../model/mapping/Mapping";
import {generateMapping} from "../../../engine/analysis/MappingGenerator";

export function generateOptMapping(source: OPlatformRepresentation, target: OPlatformRepresentation): Mapping | undefined {
  if(source && target) {
    return generateMapping(source, target)
  } else {
    return undefined
  }
}

function useLoadState(rep: ORepresentationId, obj: State<OPlatformRepresentation>) {
  let api = useApi<Api>()
  let {set} = obj
  let callback = useCallback(async ()=>{
    if(rep) {
      let platformRep = await api.getRepresentation(rep.warehouseName, rep.platformName)
      console.log("Loaded rep", rep, platformRep)
      set(platformRep)
    } else {
      set(undefined)
    }
  }, [api, rep, set])
  usePromise(callback)
}

export default function ReviewMappingLoader({first, firstLoaded, second, secondLoaded, result}: ReviewState) {
  useLoadState(first, firstLoaded)
  useLoadState(second, secondLoaded)
  let f = firstLoaded.value
  let s = secondLoaded.value
  let {value: resultValue, set: setResult} = result
  useEffect(()=>{
    setResult(s && f ? {source: f, target: s, mapping: generateMapping(f, s)} : undefined)
  }, [f, s, setResult])
  return <div>
    {(resultValue && f && s) ? <ReviewMappingView source={f} target={s} mapping={resultValue.mapping} /> : <LoadingSpinner />}
  </div>
}