import {CompRepresentationMap} from "../../../model/internal/PlatformRepresentation";
import {ComponentMappingMap} from "../../../model/mapping/Mapping";
import Job from "../../../model/emerald/Job";
import Component from "../../../model/emerald/Component";
import {Logger} from "./Logger";
import {mapComponents} from "./ComponentLayer";
import {ApiJobExport} from "../../../model/emerald/exports/ApiExport";
import {ConnectorHandlerProducer} from "./ConnectorHandler";

export interface JobMapData {
  source: CompRepresentationMap,
  target: CompRepresentationMap,
  mappings: ComponentMappingMap
}
function mapJob<T extends Job<Component>>(job: T, handlerProc: ConnectorHandlerProducer<T>, data: JobMapData, logger: Logger): T {
  let newJob = {...job}
  let componentLogger = logger.branch("Components")
  let handler = handlerProc(newJob)
  newJob.components = mapComponents(newJob.components, handler, data, componentLogger)
  return newJob
}

export function mapJobs<J extends Job<Component>>(jobs: ApiJobExport<J>[], producer: ConnectorHandlerProducer<J>, data: JobMapData, logger: Logger): ApiJobExport<J>[] {
  return jobs.map((oldJob)=>{
    let child = logger.branch(`Job - ${oldJob.info.name}`)
    let jobObject = mapJob(oldJob.jobObject, producer, data, child)
    return {
      ...oldJob,
      jobObject
    }
  })
}