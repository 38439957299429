import {SelectorValues} from "./Arguments";
import {StepInfo} from "../../generic/stepper/Step";
import ConverterRepresentationSelector from "./ConverterRepresentationSelector";
import {ApiRequestExtraProps} from "../../../api/util/ApiRequestExtraProps";

export const ConverterRepresentationStep: StepInfo<SelectorValues> = {
  component: ConverterRepresentationStepComponent,
  title: "Select Platforms"
}

export function ConverterRepresentationStepComponent(args: SelectorValues) {
  return <ApiRequestExtraProps functionName={"listRepresentations"} onSuccess={ConverterRepresentationSelector} extraProps={args} />
}