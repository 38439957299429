import {ComponentRepresentation} from "../../model/internal/ComponentRepresentation";
import {CompRepresentationMap} from "../../model/internal/PlatformRepresentation";
import {ComponentMappingMap} from "../../model/mapping/Mapping";
import {shortCircuitScorer, ShortCircuitType} from "../../util/marriage/scorers/ShortCircuitScorer";
import {mapScorer} from "../../util/marriage/string/MappingScorer";
import {equalityScorer} from "../../util/marriage/scorers/EqualityScorer";
import {textScorer} from "../../util/marriage/string/TextScorer";
import {generateMarriage} from "../../util/marriage/StableMarriageGenerator";
import {DeleteComponentMapping, UpdateComponentMapping} from "../../model/mapping/ComponentMapping";
import {generateMappingParameters} from "./MappingParameterGenerator";
import {AnyScorer} from "../../util/marriage/ScorerBase";

const scorer: AnyScorer<ComponentRepresentation> = shortCircuitScorer(
  mapScorer((x)=>x.implementationId, equalityScorer()),
  mapScorer((x)=>x.name, textScorer),
  ShortCircuitType.ShortCircuitOnOne
)

export function generateMappingComponents(source: CompRepresentationMap, target: CompRepresentationMap): ComponentMappingMap {
  let matches = generateMarriage({a: source, b: target, minScore: 0.5, scorer})
  let maps: ComponentMappingMap = {}
  matches.matched.forEach(match=>{
    let sourceComp = source[match.a]
    let targetComp = target[match.b]
    let update: UpdateComponentMapping = {
      newImplementationId: targetComp.implementationId,
      parameters: generateMappingParameters(sourceComp.parameters, targetComp.parameters),
      mappingScore: match.score
    }
    maps[match.a] = update
  })
  matches.unmatchedA.forEach((noMatch)=>{
    let deleteMapping: DeleteComponentMapping = {
      delete: true
    }
    maps[noMatch] = deleteMapping
  })
  return maps
}

