export type HeaderSize = "h1" | "h2" | "h3" | "h4" | "h5"

interface HeaderArgs {
  size?: HeaderSize,
  text: string
}
export default function Header({size, text}: HeaderArgs) {
  switch(size) {
    case "h1": return <h1>{text}</h1>
    case "h2": return <h2>{text}</h2>
    case "h3": return <h3>{text}</h3>
    case "h4": return <h4>{text}</h4>
    case "h5": return <h5>{text}</h5>
  }
  return <>{text}</>
}