import {CompRepresentationMap, PlatformRepresentation} from "../../model/internal/PlatformRepresentation";
import {Tree, TreeItem} from "@matillion/component-library";
import {ParamRepresentationMap} from "../../model/internal/ComponentRepresentation";

function ParamView({l, params}: {l: string, params: ParamRepresentationMap}) {
  return <>
    {Object.values(params)
      .map(c=><TreeItem nodeId={`${l}.${c.slot}`} label={c.name} key={c.slot} >
      </TreeItem>)
    }
  </>
}

function CompView({l, comps}: {l: string, comps: CompRepresentationMap}) {
  return <>
    {Object.values(comps)
      .map(c=><TreeItem nodeId={`${l}.${c.implementationId}`} label={c.name} key={c.implementationId} >
        <ParamView l={`${l}.${c.implementationId}`} params={c.parameters} />
      </TreeItem>)
    }
  </>
}

interface PlatformRepresentationViewArgs {
  data: PlatformRepresentation
}
export default function PlatformRepresentationView({data}: PlatformRepresentationViewArgs) {
  return <div>
    <div>
      <h3>Representation</h3>
      <h4>{data.warehouse} - {data.cloud}</h4>
    </div>
    <Tree>
      <TreeItem nodeId={"o"} label="Orchestration Components">
        <CompView l="o" comps={data.orchestrationComponents} />
      </TreeItem>
      <TreeItem nodeId={"t"} label="Transformation Components">
        <CompView l="t" comps={data.transformationComponents} />
      </TreeItem>
    </Tree>
  </div>
}