import {Message} from "../../../engine/mapping/engine/Logger";
import {useToggleState} from "../../../util/State";

function filterToWarn(messages: Message[]): Message[] {
  let x: Message[] = []
  messages.forEach((m)=>{
    if(m.class === "PARENT") {
      let children = filterToWarn(m.children)
      if(children.length > 0) {
        x.push({value: m.value, class: m.class, children})
      }
    } else if(m.class === "WARN") {
      x.push(m)
    }
  })
  return x
}

interface MessageListArgs {
  indent: number,
  message: Message
}
function MessageList({indent, message}: MessageListArgs) {
  let [open, toggleOpen] = useToggleState(true)
  return <>
    <tr>
      <td style={{paddingLeft: (indent * 5) + "px", display: "flex"}}>
        {message.children.length > 0 ? <div onClick={toggleOpen}>{open ? '-' : '+'}</div> : null}
        {message.value}
      </td>
    </tr>
    {open ? message.children.map((m,i)=><MessageList key={i} indent={indent+1} message={m} />) : null}
  </>
}

interface MessageViewArgs {
  messages: Message[]
}
export function MessageView({messages}: MessageViewArgs) {
  let [warnOnly, toggleWarnOnly] = useToggleState(false)
  let messageList = warnOnly ? filterToWarn(messages) : messages
  return <div style={{border: "1px solid gray"}}>
    <h3>Conversion Log</h3>
    <div>
      Show only warnings:
      <input type="checkbox" title="Warnings Only" checked={warnOnly} aria-label="Warnings Only" onChange={toggleWarnOnly} />
    </div>
    <table style={{fontFamily: "monospace"}}>
      {messageList.map((m, i)=><MessageList indent={0} message={m} key={i} />)}
    </table>
  </div>
}