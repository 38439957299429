import {LoadedComp, Param} from "./ApiRequestTypes";
import {ApiRequest} from "./ApiRequest";

let emptyArgs: [] = []
export interface ApiRequestExtraPropsArgs<T, Y> {
  functionName: Param<[], T>
  onSuccess: LoadedComp<T, Y>,
  extraProps: Y
}
export function ApiRequestExtraProps<T extends unknown, Y>({functionName, onSuccess, extraProps}: ApiRequestExtraPropsArgs<T, Y>) {
  return <ApiRequest args={emptyArgs} functionName={functionName} onSuccess={onSuccess} extraProps={extraProps} />
}
