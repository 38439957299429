import {ComponentMappingMap} from "../../../model/mapping/Mapping";
import {CompRepresentationMap} from "../../../model/internal/PlatformRepresentation";
import {State} from "../../../util/State";
import {useCallback, useState} from "react";
import {mergeComponents} from "../../../engine/analysis/MappingMerger";
import {DataGridColumnProps, DataGridRow} from "@matillion/component-library/dist/components/DataGrid/types";
import {ComponentRepresentation} from "../../../model/internal/ComponentRepresentation";
import {ComponentMapping, isUpdateComponentMapping} from "../../../model/mapping/ComponentMapping";
import {Button, DataGrid} from "@matillion/component-library";
import {MapData, mapMapData} from "../../../model/internal/MapHelper";
import getMergedKeys from "../../../util/MergedIterator";

function CompMappingEditActions({setEditing, id}: CompMapEditSelf) {
  let editMe = useCallback(()=>setEditing(id), [setEditing, id])
  return <div style={{display: "flex", flexGrow: 1, justifyContent: "space-between"}}>
    <Button onClick={editMe}>Edit</Button>
    <div>
      ACTIONS!
    </div>
  </div>
}

const columns: DataGridColumnProps<CompMapEditData>[] = [
  {
    title: "From",
    key: "from"
  },
  {
    title: "To",
    key: "to"
  },
  {
    title: "",
    key: "self",
    as: CompMappingEditActions
  }
]

interface CompMapEditSelf {
  id: number,
  setEditing: (id: number)=>void
  representation: MapData<ComponentRepresentation | undefined>,
  base: ComponentMapping | undefined,
  custom: ComponentMapping | undefined,
  merged: ComponentMapping
}
interface CompMapEditData extends DataGridRow {
  from: string,
  to: string
  self: CompMapEditSelf
}

interface CompMappingMapEditorArgs {
  representation: MapData<CompRepresentationMap>,
  baseMapping: ComponentMappingMap,
  current: State<ComponentMappingMap>
}
export function CompMappingEditor({representation, baseMapping, current}: CompMappingMapEditorArgs) {
  let [editing, setEditing] = useState<number | undefined>(undefined)
  console.log("Editing", editing)
  let merged = mergeComponents(baseMapping, current.value)
  let rows: CompMapEditData[] = getMergedKeys(representation.source, current.value).map((compId)=>{
    let target = current.value[compId] ?? baseMapping[compId]
    let targetId: number | null = null
    if(target && isUpdateComponentMapping(target)) {
      targetId = target.newImplementationId ?? null
    }
    let targetRepr = (targetId === null ? null : representation.target[targetId]) ?? null
    return {
      id: compId.toString(),
      from: representation.source[compId]?.name ?? "Unknown",
      to: targetRepr?.name ?? (targetId === null ? "-" : "ID " + targetId) ,
      self: {
        id: compId,
        setEditing,
        representation: mapMapData(representation, a=>a[compId]),
        base: baseMapping[compId],
        custom: current.value[compId],
        merged: merged[compId]
      }
    }
  })
  return <DataGrid columns={columns} rows={rows} />
}