import {BlockDisplay} from "./BlockType";

function ScoreBlock({left, right, display: {colour, label}}: {left: boolean, right: boolean, display: BlockDisplay}) {
  let l = left ? "6px" : "0px"
  let r = right ? "6px" : "0px"
  let min = left || right ? "7px" : undefined
  let style = {
    borderRadius: `${l} ${r} ${r} ${l}`,
    borderRight: right ? "1px solid black" : "1px solid gray",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderLeft: left ? "1px solid black" : undefined,
    minWidth: min,
    flexGrow: 1,
    height: "16px",
    backgroundColor: colour
  }
  return <div style={style} aria-label={label}/>
}

function ScoreBlockBit({index, max, display}: {index: number, max: number, display: BlockDisplay}) {
  return <ScoreBlock left={index === 0} right={index === (max - 1)} display={display} />
}

interface ScoreBlocksArgs {
  indexSoFar: number,
  max: number,
  size: number,
  display: BlockDisplay
}
export default function ScoreBlocks({indexSoFar, max, size, display}: ScoreBlocksArgs) {
  if(size === 0) return null
  return <>
    {Array.apply(null, Array(size)).map((_,index)=><ScoreBlockBit index={indexSoFar + index} max={max} display={display}/> )}
  </>
}