import {Holder} from "./MarriageElement";
import {Id, Match} from "./Types";

export type ShortCircuitBreaker<T> = (a: T, b: T)=>boolean
interface ShortCircuitResult<T, K> {
  matches: Match<K>[],
  newAList: Holder<T>[],
  newBList: Holder<T>[]
}
export function shortCircuit<T, K extends Id>(aList: Holder<T>[], bList: Holder<T>[], breaker: ShortCircuitBreaker<T>): ShortCircuitResult<T, K> {
  let matchedIdsA: Record<Id, boolean> = {}
  let matchedIdsB: Record<Id, boolean> = {}
  let matches: Match<K>[] = []
  aList.forEach((a)=>{
    bList.forEach((b)=>{
      if(breaker(a.value, b.value)) {
        matchedIdsA[a.id] = true
        matchedIdsB[b.id] = true
        matches.push({a: a.id as K, b: b.id as K, score: 1})
      }
    })
  })
  return {
    matches,
    newAList: aList.filter(x=>!matchedIdsA[x.id]),
    newBList: bList.filter(x=>!matchedIdsB[x.id])
  }
}