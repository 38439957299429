import {ParamRepresentationMap} from "../../model/internal/ComponentRepresentation";
import {shortCircuitScorer, ShortCircuitType} from "../../util/marriage/scorers/ShortCircuitScorer";
import {mapScorer} from "../../util/marriage/string/MappingScorer";
import {equalityScorer} from "../../util/marriage/scorers/EqualityScorer";
import {textScorer} from "../../util/marriage/string/TextScorer";
import {generateMarriage} from "../../util/marriage/StableMarriageGenerator";
import {ParameterRepresentation} from "../../model/internal/ParameterRepresentation";
import {DeleteParameterMapping, ParameterMapping, UpdateParameterMapping} from "../../model/mapping/ParameterMapping";
import {BuiltScorer} from "../../util/marriage/ScorerBase";

const scorer: BuiltScorer<ParameterRepresentation> = shortCircuitScorer(
  mapScorer((x)=>x.type, equalityScorer()),
  mapScorer((x)=>x.name, textScorer),
  ShortCircuitType.ShortCircuitOnZero
)

export function generateMappingParameters(source: ParamRepresentationMap, target: ParamRepresentationMap): Record<number, ParameterMapping> {
  let matches = generateMarriage<number, ParameterRepresentation>({a: source, b: target, minScore: 0.5, scorer})
  let map: Record<number, ParameterMapping> = {}
  matches.matched.forEach(match=>{
    let update: UpdateParameterMapping = {
      newSlot: match.b,
      mappingScore: match.score
    }
    map[match.a] = update
  })
  matches.unmatchedA.forEach((unmatched)=>{
    let deleteMe: DeleteParameterMapping = {
      delete: true
    }
    map[unmatched] = deleteMe
  })
  return map
}

