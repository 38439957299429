import {ComponentRepresentation} from "../../../../model/internal/ComponentRepresentation";
import {UpdateComponentMapping} from "../../../../model/mapping/ComponentMapping";
import {Button, Field} from "@matillion/component-library";

interface UpdateComponentInspectionViewArgs {
  source: ComponentRepresentation,
  target: ComponentRepresentation,
  mapping: UpdateComponentMapping,
  close: ()=>void
}
export default function UpdateComponentInspectionView({source, target, mapping, close}: UpdateComponentInspectionViewArgs) {
  return <div>
    <Button onClick={close}>Close</Button>
    <div>
      {source.name !== target.name ? <>
          <Field value={source.name} title="SourceComponent Name" />
          <Field value={target.name} title="Target Component Name" />
        </> :
        <Field value={source.name} title="Component Name" />}
    </div>
  </div>
}