import {OrchestrationJob} from "../../../model/emerald/Orchestration";
import {TransformationJob} from "../../../model/emerald/Transformation";
import {ConnectorMap} from "../../../model/emerald/Connector";
import {Logger} from "./Logger";

export interface ConnectorHandler {
  removeComponent: (id: number, logger: Logger)=>void
}

export type ConnectorHandlerProducer<J> = (job: J)=>ConnectorHandler

function fixConnectorMap(map: ConnectorMap, id: number, logger: Logger): ConnectorMap {
  let newMap: ConnectorMap = {}
  Object.values(map).forEach(it=>{
    if(it.sourceID !== id && it.targetID !== id) {
      newMap[it.id] = it
    } else {
      logger.info("Removing connector (" + JSON.stringify(it) + ")")
      console.debug("Removing connector", it, id)
    }
  })
  return newMap
}

export function getOrchestrationJobConnectorHandler(job: OrchestrationJob): ConnectorHandler {
  let keys = ["successConnectors", "failureConnectors", "unconditionalConnectors", "trueConnectors", "falseConnectors", "iterationConnectors"]
  return {
    removeComponent(id, logger) {
      keys.forEach(k=>{
        let j = job as any
        if(j[k])
          j[k] = fixConnectorMap(j[k], id, logger)
      })
      if(job.basicConnectors)
        Object.keys(job.basicConnectors).forEach(type=>job.basicConnectors[type] = fixConnectorMap(job.basicConnectors[type], id, logger))
    }
  }
}
export function getTransformationJobConnectorHandler(job: TransformationJob): ConnectorHandler {
  return {
    removeComponent(id, logger) {
      job.connectors = fixConnectorMap(job.connectors, id, logger)
    }
  }
}