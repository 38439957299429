import {Setter} from "./Setter";
import {useCallback, useState} from "react";

export default function useSavedState<T>(name: string, defaultValue: T): [T, Setter<T>, ()=>void] {
  let [state, setState] = useState<T>(()=>{
    try {
      let loaded = localStorage.getItem(name)
      if(loaded) return JSON.parse(loaded) as T
    } catch(e) {
      console.error("Error loading saved state [")
    }
    return defaultValue
  })
  let save = useCallback(()=>localStorage.setItem(name, JSON.stringify(state)), [name, state])
  return [state, setState, save]
}