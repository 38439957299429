import Component from "../../../model/emerald/Component";
import {IdMap} from "../../../model/emerald/HasId";
import {ComponentMappingMap} from "../../../model/mapping/Mapping";
import {Logger} from "./Logger";
import {getName} from "../../../model/emerald/JobObjectHelper";
import {
  ComponentMapping,
  isUpdateComponentMapping,
  UpdateComponentMapping
} from "../../../model/mapping/ComponentMapping";
import {CompRepresentationMap} from "../../../model/internal/PlatformRepresentation";
import {ComponentRepresentation} from "../../../model/internal/ComponentRepresentation";
import {mapParameters, ParamMapData} from "./ParameterLayer";
import {ConnectorHandler} from "./ConnectorHandler";

interface CompData {
  source: ComponentRepresentation,
  targets: CompRepresentationMap,
  mapping: ComponentMapping
}
export interface CompMapData {
  source: CompRepresentationMap,
  target: CompRepresentationMap,
  mappings: ComponentMappingMap
}

function getParamData(mapping: UpdateComponentMapping, source: ComponentRepresentation, target: ComponentRepresentation): ParamMapData {
  return {
    sources: source.parameters,
    targets: target.parameters,
    mappings: mapping.parameters
  }
}
function mapComponent<C extends Component>(component: C, data: CompData | undefined, logger: Logger): C | undefined {
  let newC = {...component}
  if(data) {
    let {mapping, source, targets} = data
    if (mapping && isUpdateComponentMapping(mapping)) {
      let target = targets[component.implementationID] ?? source
      if (mapping.newImplementationId && mapping.newImplementationId !== component.implementationID) {
        target = targets[mapping.newImplementationId]
        logger.info(`Changing component implementation id to ${mapping.newImplementationId}`)
        newC.implementationID = mapping.newImplementationId!
      }
      let paramLogger = logger.branch("Parameters")
      newC.parameters = mapParameters(component.parameters, getParamData(mapping, source, target), paramLogger)
      return newC
    }
  }
  logger.warn("Component has no associated target and is getting deleted.")
  return undefined
}

function getMapData(data: CompMapData, id: number): CompData | undefined {
  let {mappings, source, target} = data
  let mapping = mappings[id]
  if(mapping) {
    return {
      mapping,
      source: source[id],
      targets: target
    }
  }
}
export function mapComponents<C extends Component>(components: IdMap<C>, connectorHandler: ConnectorHandler, mapData: CompMapData, logger: Logger): IdMap<C> {
  let newMap: IdMap<C> = {}
  Object.values(components).forEach((oldComponent)=>{
    let implType = mapData.source[oldComponent.implementationID]?.name ?? "Unknown Type"
    let singleCompLogger = logger.branch(`Component - ${getName(oldComponent)} - ${implType}`)
    let newComponent = mapComponent(oldComponent, getMapData(mapData, oldComponent.implementationID), singleCompLogger)
    if(newComponent) {
      newMap[newComponent.id] = newComponent
    } else {
      connectorHandler.removeComponent(oldComponent.id, singleCompLogger)
    }
  })
  return newMap
}