import {Setter} from "./Setter";
import {useCallback, useState} from "react";

export interface State<T> {
  value: T,
  set: Setter<T>
}
export function useStateObject<T>(initial: T | (()=>T)) {
  let [value, set] = useState(initial)
  return {value, set}
}

export type ToggleState = [
  boolean,
  ()=>void,
  Setter<boolean>
]
export function useToggleState(initial: boolean): ToggleState {
  let [value, setValue] = useState(initial)
  let toggle = useCallback(()=>setValue(x=>!x), [setValue])
  return [value, toggle, setValue]
}