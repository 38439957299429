import {
  DynamicHelp,
  DynamicHelpProvider,
  Page,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuItemGroup
} from "@matillion/component-library";
import {Link, Outlet, useMatch} from "react-router-dom";
import {BasePages} from "./Pages";

interface SidebarItemArgs {
  matchUrl: string | undefined,
  url: string,
  label: string
}
export function SidebarItem({matchUrl, url, label}: SidebarItemArgs) {
  let match = useMatch(matchUrl ?? url)
  console.log(matchUrl, url, match)
  return <SidebarMenuItem active={match !== null}>
    <Link to={url}>{label}</Link>
  </SidebarMenuItem>
}

export default function Sidebar() {
  return <SidebarMenu>
    <SidebarMenuItemGroup>
      {BasePages.map(p=><SidebarItem url={p.url} matchUrl={p.matchUrl} label={p.label} />)}
    </SidebarMenuItemGroup>
  </SidebarMenu>
}

export function SidebarPage() {
  return <DynamicHelpProvider root={"help/"} initialPath={"index.md"}>
    <Page sidebar={<Sidebar />} helpbar={<DynamicHelp />}>
      <Outlet />
    </Page>
  </DynamicHelpProvider>
}