export default function getMergedKeys(...data: Record<number, unknown>[]): number[] {
  let found: Record<number, true> = {}
  let numbers: number[] = []
  data.forEach(base=>{
    Object.keys(base).forEach(kStr=>{
      let k = parseInt(kStr)
      if(!found[k]) {
        found[k] = true
        numbers.push(k)
      }
    })
  })
  return numbers
}