import {ShortCircuitBreaker} from "../ShortCircuiter";
import {AnyScorer, BuiltScorer, extractScorer, Scorer} from "../ScorerBase";

function getBreaker<T>(shorter?: ShortCircuitBreaker<T>, fallback?: ShortCircuitBreaker<T>): ShortCircuitBreaker<T> | undefined {
  if(!shorter && !fallback) return undefined
  return (a, b)=>{
    return (shorter !== undefined ? shorter(a,b) : false) || (fallback !== undefined ? fallback(a,b) : false)
  }
}

export enum ShortCircuitType {
  ShortCircuitOnOne,
  ShortCircuitOnZero
}
export function shortCircuitScorer<T>(shorter: AnyScorer<T>, fallback: AnyScorer<T>, type: ShortCircuitType): BuiltScorer<T> {
  let shortCircuitAmount = type === ShortCircuitType.ShortCircuitOnOne ? 1 : 0
  let {scorer: shorterScorer, breaker: shorterBreaker} = extractScorer(shorter)
  let {scorer: fallbackScorer, breaker: fallbackBreaker} = extractScorer(fallback)
  let scorer: Scorer<T> = (a, b)=>{
    let firstScore = shorterScorer(a, b)
    if(firstScore === shortCircuitAmount) return shortCircuitAmount

    return fallbackScorer(a, b)
  }
  let breaker = type === ShortCircuitType.ShortCircuitOnOne ? getBreaker(shorterBreaker, fallbackBreaker) : fallbackBreaker
  return {scorer, breaker}
}