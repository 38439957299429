import {UiExport} from "../../../model/emerald/exports/UiExport";
import {Input} from "@matillion/component-library";
import {useCallback} from "react";
import {ExportType} from "../../../model/emerald/exports/JobExport";

export function FileLoader({setFileData}: {setFileData: (fileData: ExportType)=>void}) {
  let onChange = useCallback((e)=>{
    let files = e.target.files
    let reader = new FileReader()
    reader.addEventListener("load", (x)=>{
      let result = x?.target?.result as string
      try {
        let data = JSON.parse(result) as UiExport
        setFileData(data)
      } catch(e) {
        console.error(e)
      }
    })
    reader.readAsText(files[0])
  }, [setFileData])
  return <Input type="file" onChange={onChange}/>
}