import PlatformRepresentationList from "./PlatformRepresentationList";
import {useCallback, useState} from "react";
import {RepresentationId} from "../../model/internal/RepresentationId";
import {Button} from "@matillion/component-library";
import {ApiRequestExtraProps} from "../../api/util/ApiRequestExtraProps";
import PlatformRepresentationView from "./PlatformRepresentationView";
import {ApiRequestArgs} from "../../api/util/ApiRequestArgs";

export default function RepresentationView() {
  let [open, setOpen] = useState<RepresentationId | undefined>()
  let close = useCallback(()=>setOpen(undefined), [setOpen])
  if(!open) {
    return <div>
      <ApiRequestExtraProps functionName="listRepresentations" onSuccess={PlatformRepresentationList} extraProps={{setOpen}}/>
    </div>
  } else {
    return <div>
      <Button onClick={close}>Close</Button>
      <ApiRequestArgs args={[open.warehouseName, open.platformName]} functionName="getRepresentation" onSuccess={PlatformRepresentationView } />
    </div>
  }
}