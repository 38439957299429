import {Setter} from "../Setter";
import {State} from "../State";
import {useCallback} from "react";

export type ArgKey<A, B> = {
  [Property in keyof A]: A[Property] extends B ? Property : never
}[keyof A]

export function useSubSetter<A, B>(key: ArgKey<A, B>, setter: Setter<A>): Setter<B> {
  return useCallback((newValue)=> {
    setter(prevA=>{
      let oldB = prevA[key] as any as B
      let newB: B | null = null
      if(typeof newValue === "function") {
        let f = newValue as (prevState: B)=>B
        newB = f(oldB)
      } else {
        newB = newValue
      }
      return {...prevA, [key]: newB}
    })
  }, [key, setter])
}

export function useSubState<A, B>(key: ArgKey<A, B>, state: State<A>): State<B> {
  let b = state.value[key] as any as B
  return {
    value: b,
    set: useSubSetter(key, state.set)
  }
}