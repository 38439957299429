export type ParameterMapping = UpdateParameterMapping | DeleteParameterMapping

export interface UpdateParameterMapping {
  newSlot: number,
  mappingScore: number
}

export interface DeleteParameterMapping {
  delete: true
}

export function isDeleteParameterMapping(mapping: ParameterMapping): mapping is DeleteParameterMapping {
  return (mapping as any).delete
}

export function isUpdateParameterMapping(mapping: ParameterMapping): mapping is UpdateParameterMapping {
  return (mapping as any).newSlot
}