import {useCallback, useState} from "react";
import {CustomMapping} from "../../model/mapping/CustomMapping";
import CreateMappingView from "./create/CreateMappingView";
import EditMappingView from "./edit/EditMappingView";
import ListMappingsView from "./list/ListMappingsView";
import {useHelp} from "@matillion/component-library";

export default function CustomMappingView() {
  useHelp("mappings.md")
  let [editing, setEditing] = useState<CustomMapping | boolean | undefined>(undefined)
  let stopEditing = useCallback(()=>setEditing(undefined), [])
  let startCreating = useCallback(()=>setEditing(true), [])
  if(typeof editing === "boolean") {
    if(editing)
      return <CreateMappingView close={stopEditing} />
  } else if(editing) {
    return <EditMappingView close={stopEditing} editing={editing} setEditing={setEditing} />
  }
  return <ListMappingsView setEditing={setEditing} setCreating={startCreating} />
}