import {ComponentMappingMap, Mapping} from "../../model/mapping/Mapping";
import {CustomMapping} from "../../model/mapping/CustomMapping";
import {ComponentMapping, isDeleteComponentMapping, UpdateComponentMapping} from "../../model/mapping/ComponentMapping";


function mergeComponent(source: ComponentMapping, custom: ComponentMapping): ComponentMapping {
  if(!custom) return source
  if(isDeleteComponentMapping(source)|| isDeleteComponentMapping(custom)) return custom
  let newOne: UpdateComponentMapping = {...source}
  newOne.newImplementationId = custom.newImplementationId
  Object.keys(custom.parameters).forEach(paramSlotStr=>{
    let paramSlot = parseInt(paramSlotStr)
    newOne.parameters[paramSlot] = custom.parameters[paramSlot]
  })
  return newOne
}

export function mergeComponents(source: ComponentMappingMap, custom: ComponentMappingMap): ComponentMappingMap {
  let newMap = {...source}
  Object.keys(custom).forEach(keyStr=>{
    let key = parseInt(keyStr)
    let newMapping: ComponentMapping = custom[key]
    let oldMapping = source[key]
    if(oldMapping) {
      newMap[key] = mergeComponent(oldMapping, newMapping)
    } else {
      newMap[key] = newMapping
    }
  })
  return newMap
}

export function mergeMappings(source: Mapping, custom: CustomMapping): Mapping {
  let newMapping = {...source}
  newMapping.orchestrationComponents = mergeComponents(source.orchestrationComponents, custom.mapping.orchestrationComponents)
  newMapping.transformationComponents = mergeComponents(source.transformationComponents, custom.mapping.transformationComponents)
  return newMapping
}