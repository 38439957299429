import {TransformationJob} from "./Transformation";
import Component from "./Component";
import {IdMap} from "./HasId";

export function getSingleParameter(component: Component, slot: number) {
  return component.parameters[slot].elements[1].values[1].value
}

export function getName(component: Component) {
  return getSingleParameter(component, 1)
}

export function setName(component: Component, newName: string) {
  component.parameters[1].elements[1].values[1].value = newName
}

export function maxId(idMap: IdMap<any>) {
  return Object.values(idMap).map(x=>x.id).reduce((p, c)=>c > p ? c : p, 0)
}

export function maxTJobId(job: TransformationJob): number {
  return Math.max(maxId(job.connectors), maxId(job.components))
}