import {Typography} from "@matillion/component-library";

export default function InitialPage() {
  return <div>
    <Typography as="h2" format="dtm">Bodger 3</Typography>
    <Typography>
      Bodger 3 is a tool for beginning the conversion of jobs between different platforms.
    </Typography>
    <Typography>
      To get started, create an API key, generate and install the Populator script, visit your METL instances and then you are ready to convert jobs!
    </Typography>
  </div>
}