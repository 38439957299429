import {ComponentMappingMap} from "../../../../model/mapping/Mapping";
import {CompRepresentationMap} from "../../../../model/internal/PlatformRepresentation";
import {isUpdateComponentMapping} from "../../../../model/mapping/ComponentMapping";
import {ParamRepresentationMap} from "../../../../model/internal/ComponentRepresentation";
import {ParameterMapping} from "../../../../model/mapping/ParameterMapping";
import {DataGridColumnProps} from "@matillion/component-library/dist/components/DataGrid/types";
import {ParamScoreBar} from "./ParameterScore";
import {DataGrid} from "@matillion/component-library";
import {ComponentActionArgs, ComponentActions} from "./ComponentActions";

function ParamList(props: {source: ParamRepresentationMap, target: ParamRepresentationMap, mapping: Record<number, ParameterMapping>} | undefined) {
  if(props && props.mapping) {
    return <div style={{width: "150px"}}>
      <ParamScoreBar {...props} />
    </div>
  } else {
    return null
  }
}

interface CompListParam {
  source: ParamRepresentationMap,
  target: ParamRepresentationMap,
  mapping: Record<number, ParameterMapping>
}
interface CompListGridRow {
  id: string,
  sourceName: string,
  targetName: string,
  score: string
  param?: CompListParam,
  action: ComponentActionArgs
  realScore: number
}
const columns: DataGridColumnProps<CompListGridRow>[] = [
  {
    key: "sourceName",
    title: "From"
  },
  {
    key: "targetName",
    title: "To"
  },
  {
    key: "score",
    title: "Score"
  },
  {
    key: "param",
    title: "Parameters",
    as: ParamList
  },
  {
    key: "action",
    title: "",
    as: ComponentActions
  }
]

interface ComponentListArgs {
  source: CompRepresentationMap,
  target: CompRepresentationMap,
  mapping: ComponentMappingMap,
  open: (id: number)=>void
}
export function ComponentList({source, mapping, target, open}: ComponentListArgs) {
  let data: CompListGridRow[] = Object.keys(mapping).map((idStr)=>{
    let id = parseInt(idStr)
    let s = source[id as number]
    let m = mapping[id as number]
    let action: ComponentActionArgs = {
      id, open
    }
    if(isUpdateComponentMapping(m)) {
      let t = target[m.newImplementationId ?? id]
      let param: CompListParam | undefined = undefined
      if(m.parameters) {
        param = {
          source: s.parameters,
          target: t.parameters,
          mapping: m.parameters
        }
      }
      return {
        id: idStr,
        action,
        sourceName: s.name,
        targetName: t.name,
        score: m.mappingScore.toString(),
        param,
        realScore: m.mappingScore,
      }
    } else {
      return {
        id: idStr,
        action,
        sourceName: s.name,
        targetName: "",
        score: "0",
        realScore: 0
      }
    }
  }).sort((a,b)=>b.realScore - a.realScore)
  return <DataGrid columns={columns} rows={data as any} />
}