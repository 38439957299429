import {ParameterMapping} from "./ParameterMapping";

export type ComponentMapping = UpdateComponentMapping | DeleteComponentMapping

export type ParamMappingMap = Record<number, ParameterMapping>
export interface UpdateComponentMapping {
  newImplementationId?: number
  mappingScore: number,
  parameters: ParamMappingMap
}

export interface DeleteComponentMapping {
  delete: true
}

export function isDeleteComponentMapping(mapping: ComponentMapping): mapping is DeleteComponentMapping {
  return (mapping as any).delete
}

export function isUpdateComponentMapping(mapping: ComponentMapping): mapping is UpdateComponentMapping {
  return (mapping as any).newImplementationId
}