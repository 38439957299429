import {StepState} from "../../generic/stepper/Step";
import {RepresentationId} from "../../../model/internal/RepresentationId";
import {SelectorValues} from "../1select/Arguments";
import {PlatformRepresentation} from "../../../model/internal/PlatformRepresentation";
import {State, useStateObject} from "../../../util/State";
import {ORepresentationId} from "../1select/Types";
import {OConverterData} from "../3convert/Arguments";

function validate(id: ORepresentationId, rep: OPlatformRepresentation) {
  return id !== undefined && rep !== undefined && id.warehouseName === rep.warehouse && id.platformName === rep.cloud
}

export type OPlatformRepresentation = PlatformRepresentation | undefined
export interface ReviewState extends StepState {
  first?: RepresentationId
  second?: RepresentationId,
  firstLoaded: State<OPlatformRepresentation>,
  secondLoaded: State<OPlatformRepresentation>,
  result: State<OConverterData>
}
export function useReviewArguments(input: SelectorValues) {
  let firstLoaded = useStateObject<OPlatformRepresentation>(undefined)
  let secondLoaded = useStateObject<OPlatformRepresentation>(undefined)
  let firstValid = validate(input.firstChoice.value, firstLoaded.value)
  let secondValid = validate(input.secondChoice.value, secondLoaded.value)
  let result = useStateObject<OConverterData>(undefined)
  let valid = firstValid && secondValid
  return {
    first: input.firstChoice.value,
    second: input.secondChoice.value,
    firstLoaded,
    secondLoaded,
    valid,
    progress: (firstValid ? 1 : 0) + (secondValid ? 1 : 0),
    result
  }
}